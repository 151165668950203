import { Nav, Navbar } from 'react-bootstrap';
import '../../index.css';

import { useLinkNavigation } from './MenuFonctions';
import { useLocation } from 'react-router-dom';

const LinkPublicMenu: React.FC<{ onExpandedChange: () => void }> = ({ onExpandedChange }) => {

    const { createLink } = useLinkNavigation();
    const location = useLocation();
    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
        onExpandedChange(); // Appel de la fonction de mise à jour de l'état expanded
      };


    return (
        <>
            <Navbar.Collapse>
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => handleClick("")} className={`mx-3 link-header ${location.pathname === "/" ? "active" : ""}`} >
                            Accueil
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("services")} className={`mx-3 link-header ${location.pathname === "/services" ? "active" : ""}`}>  
                            Services{' '}
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("nos-vehicules-avec-chauffeur")} className={`mx-3 link-header ${location.pathname === "/nos-vehicules-avec-chauffeur" ? "active" : ""}`} >
                            Véhicules{' '}
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("references-clients")} className={`mx-3 link-header ${location.pathname === "/references-clients" ? "active" : ""}`} >
                            Références clients{' '}
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("reglementation")} className={`mx-3 link-header ${location.pathname === "/reglementation" ? "active" : ""}`} >
                            Réglementation{' '}
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("contacts")} className={`mx-3 link-header ${location.pathname === "/contacts" ? "active" : ""}`} >
                            Contacts{' '}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>      
        </>
    );

};

export default LinkPublicMenu


