
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Form, InputGroup, FormControl, Button, FormGroup, FormFloating, Modal, Nav } from 'react-bootstrap';
import { geolocalisation, getAddressFromCoordinates, initAutocomplete, geocodeAddress } from '../../utils/devisfunction';
import { toggleField } from '../../utils/globalfunctions';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../utils/usercontext';

type Devis = {
  departureaddress: string;
  arrivaladdress: string;
  typetrajet: string;
  nbrepax: number;
  datego?: string | number | readonly string[] | undefined;
  dateback?: string | number | readonly string[] | undefined;
  gochoice?: string;
  backchoice?: string;
  hourgo?: string;
  hourback?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  personnaldata?: boolean;
  description?: string;
  statut: string;
  tarif: number;
  nbrevehicule?: number;
  vehicule?: string;
};

type UserProfil = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
};

const DevisCover: React.FC = () => {
  const { user } = useContext(UserContext);

  const [devis, setDevis] = useState<Devis>({
    departureaddress: '',
    arrivaladdress: '',
    typetrajet: '',
    nbrepax: 0,
    gochoice: 'Départ à',
    hourgo: '09:00',
    hourback: '17:00',
    backchoice: 'Départ à',
    personnaldata: false,
    statut: 'Non lu',
    tarif: 0,
  });
  
  const [submittedcover, setSubmittedCover] = useState(false);
  const [submitteddevis, setSubmittedDevis] = useState(false);
  const [personnaldata, setPersonnalData] = useState(false);

  const handleGeolocDepartureClick = async () => {
    try {
      const { latitude, longitude } = await geolocalisation();
      const address = await getAddressFromCoordinates(latitude, longitude);
      setDevis({ ...devis, departureaddress: address });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGeolocArrivalClick = async () => {
    try {
      const { latitude, longitude } = await geolocalisation();
      const address = await getAddressFromCoordinates(latitude, longitude);
      setDevis({ ...devis, arrivaladdress: address });
    } catch (error) {
      console.error(error);
    }
  };

  const coverDevis = ["Aller-retour", "Aller simple", "Séjour/Evènement"]; 
  const todayselect = new Date().toISOString().split('T')[0]; // Obtenir la date actuelle au format ISO (AAAA-MM-JJ)

  useEffect(() => {
    // Charger le script de l'API Google Maps de manière asynchrone
    configureAutocomplete();
  }, []);
  
  const navigate = useNavigate(); 
  function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
  }

  const [validated, setValidated] = useState(false);

  const [typetrajet, setTypetrajet] = useState('');

  async function saveDevis(event: any) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
  
    setValidated(true);
    let updatedDevis = {...devis};
    if (user) {
        const response = await fetch(`${process.env.REACT_APP_SERVEURADRESS}/firebase/getDataByUidFirebase?uid=${user.uid}&database=users`);
        if (!response.ok) {
          throw new Error('Erreur réseau');
        }
        const userProfil = await response.json();
        updatedDevis = {
          ...devis,
          firstname: userProfil.firstname,
          lastname: userProfil.lastname,
          email: userProfil.email,
          phone: userProfil.phone,
        };
    }
    setTypetrajet(devis.typetrajet)
    if(devis.typetrajet === 'Séjour/Evènement') {
      await fetch(`${process.env.REACT_APP_SERVEURADRESS}/devis/createContact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: updatedDevis
        })
      })
      .then(response => {
        if (!response.ok) {
            throw new Error('Erreur réseau');
        }
        return response.json();
      })
      .catch(error => console.error('Erreur lors de la création des données', error));
    } else { 
      await fetch(`${process.env.REACT_APP_SERVEURADRESS}/devis/createDevis`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: updatedDevis
        })
      })
      .then(response => {
        if (!response.ok) {
            throw new Error('Erreur réseau');
        }
        return response.json();
      })
      .catch(error => console.error('Erreur lors de la création des données', error));
    }
    setSubmittedCover(false);
    setSubmittedDevis(true);
    setDevis({...devis,
      departureaddress: '',
      arrivaladdress: '',
      typetrajet: '',
      nbrepax: 0,
      datego: 'yyyy-MM-dd',
      dateback: 'yyyy-MM-dd',
      gochoice: 'Départ à',
      backchoice: 'Départ à',
      hourgo: '09:00',
      hourback:'17:00',
      personnaldata: false,
      description: '',
    });
    configureAutocomplete();
    setValidated(false);
  }

  function configureAutocomplete() {
    const loadGoogleMapsScript = () => {
      const apiKey = process.env.REACT_APP_GOOGLEAPIKEY;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&language=fr`;
      script.onload = () => {
        // Lorsque le script est chargé, initialisez l'autocomplétion
        initAutocomplete('departureaddress', (address) => {
          setDevis((prevDevis: any) => ({ ...prevDevis, departureaddress: address }));
        });
        initAutocomplete('arrivaladdress', (address) => {
          setDevis((prevDevis: any) => ({ ...prevDevis, arrivaladdress: address }));
        });
      };
      document.head.appendChild(script);
    };

    loadGoogleMapsScript();
}

  return (
    <>
    <Container>

      {!submittedcover && (
        <Form>
          <Row className="text-center">
            <Col lg={3}>
              <InputGroup className="m-0">
                <FormControl
                  id="departureaddress"
                  data-field="departureaddress"
                  required
                  value={devis.departureaddress}
                  onChange={(e) => {
                    setDevis({ ...devis, departureaddress: e.target.value })
                  }}
                  type="text"
                  placeholder="Départ : adresse, gare, lieu..."
                />
                <div className="p-0">
                  <Button
                    id="GeolocDeparture"
                    onClick={handleGeolocDepartureClick}
                    variant="outline-secondary"
                    className="bg-secondary m-0"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="text-kease bg-secondary" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg>
                  </Button>
                </div >
              </InputGroup>
            </Col>
            <Col lg={3}>
              <InputGroup className="m-0">
                <FormControl
                  id="arrivaladdress"
                  data-field="arrivaladdress"
                  required
                  value={devis.arrivaladdress}
                  onChange={(e) =>
                    setDevis({ ...devis, arrivaladdress: e.target.value })
                  }
                  type="text"
                  placeholder="Arrivée : adresse, gare, lieu..."
                />
                <div className="p-0">
                  <Button
                    id="GeolocArrival"
                    onClick={handleGeolocArrivalClick}
                    variant="outline-secondary"
                    className="bg-secondary"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="text-kease bg-secondary" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg>
                  </Button>
                </div >
              </InputGroup>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Control id="Typetrajet" required as="select"
                  value={devis.typetrajet}
                  onChange={(e) => setDevis({ ...devis, typetrajet: e.target.value }) }
                >
                  <option value="">Type de voyage</option>
                    {coverDevis.map((choice, index) => ( <option key={index} value={choice}> {choice}
                  </option>
                ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <div className="submit">
                <Button id="submit-cover" type="submit" variant="warning" className="w-100"
                  disabled={!devis.departureaddress || !devis.arrivaladdress || !devis.typetrajet}
                  onClick={() => toggleField(setSubmittedCover)}
                >
                  <strong>Devis gratuit</strong>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}

      <Modal show={submittedcover} dialogClassName="main-modal" onHide={() => toggleField(setSubmittedCover)}>
        <Form className="bg-logo"  noValidate validated={validated} onSubmit={saveDevis}>

          <Modal.Header closeButton className="bg-kease">
            <Modal.Title>Etablir un Devis</Modal.Title>
          </Modal.Header>
          <Modal.Header className="d-flex justify-content-center">
            <Modal.Title>Votre Trajet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="">
                <InputGroup.Text>Départ</InputGroup.Text>
                <FormControl id="departureaddress" data-field="departureaddress" type="text" required
                  value={devis.departureaddress}
                  onChange={(e) => { setDevis({ ...devis, departureaddress: e.target.value }) }}
                  placeholder="Départ : adresse, gare, lieu..."
                />
                <div className="p-0">
                  <Button id="GeolocDeparture" variant="outline-secondary" className="bg-secondary m-0"
                    onClick={handleGeolocDepartureClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="text-kease bg-secondary" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg>
                  </Button>
                </div >
              </InputGroup> 
              <br/>
              <InputGroup className="">
                <InputGroup.Text>Arrivée</InputGroup.Text>
                <FormControl id="arrivaladdress" data-field="arrivaladdress" type="text" required
                  value={devis.arrivaladdress}
                  onChange={(e) => { setDevis({ ...devis, arrivaladdress: e.target.value }) }}
                  placeholder="Arrivée : adresse, gare, lieu..."
                />
                <div className="p-0">
                  <Button id="GeolocDeparture" variant="outline-secondary" className="bg-secondary m-0"
                    onClick={handleGeolocArrivalClick}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="text-kease bg-secondary" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
                    </svg>
                  </Button>
                </div >
              </InputGroup> 
              <br/>
              <Row>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">Type de voyage</InputGroup.Text>
                      <Form.Control id="Typetrajet" required as="select"
                        value={devis.typetrajet}
                        onChange={(e) => setDevis({ ...devis, typetrajet: e.target.value }) }
                      >
                          {coverDevis.map((choice, index) => ( <option key={index} value={choice}> {choice}
                        </option>
                      ))}
                      </Form.Control>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">Nombre de passagers</InputGroup.Text>
                    <Form.Control type="number" required
                      min="1"
                      value={devis.nbrepax}
                      onChange={(e) => setDevis({ ...devis, nbrepax: parseInt(e.target.value) }) }
                    />
                    <Form.Control.Feedback type="invalid">
                      Vous devez saisir un nombre de passagers.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <br/>
              <br/>
              {devis.typetrajet === 'Séjour/Evènement' && (
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Décrivez votre projet ici</Form.Label>
                <Form.Control as="textarea" rows={6} 
                  onChange={(e) => setDevis({ ...devis, description: e.target.value }) }/>
              </Form.Group>
              )}
              {devis.typetrajet !== 'Séjour/Evènement' && (
              <>
              <Row className="border border-secondary rounded">
                <InputGroup.Text className="text-start d-lg-none bg-secondary rounded-start text-white decale-information m-0 w-50">
                  Information de départ
                </InputGroup.Text>
                <InputGroup.Text className="text-start d-none d-lg-block bg-secondary rounded-start text-white decale-information m-0 w-25">
                  Information de départ
                </InputGroup.Text>
                <Row className="text-center mb-3 justify-content-between">
                  <Col lg={3}>
                      <input type="date" id="datego" className="date-select form-control" required
                        value={devis.datego}
                        onChange={(e) => setDevis({ ...devis, datego: e.target.value }) }
                        min={todayselect}
                      />
                  </Col>
                  <Col lg={2} className="align-self-center text-start p-0 m-0 d-flex flex-row flex-lg-column justify-content-evenly">

                    <small>
                      <label className="form-check">
                        <input type="radio" name="devis.gochoice"
                          checked={devis.gochoice === 'Départ à'}
                          onChange={() => setDevis({ ...devis, gochoice: 'Départ à' })}
                          value="Départ à"
                        />{' '} Départ{' '}à
                      </label>
                      </small>
                      <small>
                      <label className="form-check">
                        <input type="radio" name="devis.gochoice"
                          checked={devis.gochoice === 'Arrivée vers'}
                          onChange={() => setDevis({ ...devis, gochoice: 'Arrivée vers' })}
                          value="Arrivée vers"
                        />{' '} Arrivée{' '}vers
                      </label>
                    </small>

                  </Col>

                  <Col lg={3}>
                      <input type="time" className="form-control" defaultValue='09:00' required
                        value={devis.hourgo}
                        onChange={(e) => setDevis({ ...devis, hourgo: e.target.value }) } 
                      />
                  </Col>

                </Row>
            </Row>
            <br/>
            <br/>
            {devis.typetrajet !== 'Aller simple' && (
            <Row className="border border-secondary rounded">
                <InputGroup.Text className="text-start d-lg-none bg-secondary rounded-start text-white decale-information m-0 w-50">
                  Information de retour
                </InputGroup.Text>
                <InputGroup.Text className="text-start d-none d-lg-block bg-secondary rounded-start text-white decale-information m-0 w-25">
                  Information de retour
                </InputGroup.Text>
                <Row className="text-center mb-3 justify-content-between">
                  <Col lg={3}>
                      <input type="date" id="dateback" className="date-select form-control" required
                        value={devis.dateback}
                        onChange={(e) => setDevis({ ...devis, dateback: e.target.value }) }
                        min={todayselect} 
                      />
                  </Col>

                  <Col lg={2} className="align-self-center text-start p-0 m-0 d-flex flex-row flex-lg-column justify-content-evenly">
                      <small>
                        <label className="form-check">
                          <input type="radio" name="devis.backchoice"
                            checked={devis.backchoice === 'Départ à'}
                            onChange={() => setDevis({ ...devis, backchoice: 'Départ à' })}
                            value="Départ à"
                          />{' '}Départ{' '}à
                        </label>
                        </small>

                      <small>
                        <label className="form-check">
                          <input type="radio" name="devis.backchoice"
                            checked={devis.backchoice === 'Arrivée vers'}
                            onChange={() => setDevis({ ...devis, backchoice: 'Arrivée vers' })}
                            value="Arrivée vers"
                          />{' '}Arrivée{' '}vers
                        </label>
                      </small>
                  </Col>
                
                  <Col lg={3}>
                      <input type="time" className="form-control" defaultValue="17:00" required
                        value={devis.hourback}
                        onChange={(e) => setDevis({ ...devis, hourback: e.target.value }) }
                      />
                  </Col>
                  

                </Row>
            </Row>
            )}
            </>
            )}
          </Modal.Body>

          <Modal.Header className="d-flex justify-content-center">
            {!user ? (
              <Modal.Title>Vos coordonnées</Modal.Title>
            ) : 
            <Modal.Title>votre devis sera attaché à l'adresse mail : {user.email}</Modal.Title>
            }
          </Modal.Header>

          <Modal.Body>
            {!user ? (
              <>
              <Row>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">Prénom</InputGroup.Text>
                      <Form.Control required 
                        value={devis.firstname}
                        onChange={(e) => setDevis({ ...devis, firstname: e.target.value }) }
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Vous devez saisir un prénom.
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">Nom</InputGroup.Text>
                      <Form.Control required 
                        value={devis.lastname}
                        onChange={(e) => setDevis({ ...devis, lastname: e.target.value }) }
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Vous devez saisir un nom de famille.
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">email</InputGroup.Text>
                      <Form.Control required 
                        type="email"
                        value={devis.email}
                        onChange={(e) => setDevis({ ...devis, email: e.target.value }) }
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}"
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                      Vous devez saisir un email correct.
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup className="">
                    <InputGroup.Text className="bg-secondary m-0 rounded-start text-white">Téléphone</InputGroup.Text>
                      <Form.Control required
                        type="tel"
                        value={devis.phone}
                        onChange={(e) => setDevis({ ...devis, phone: e.target.value }) }
                        pattern="[0-9]{10}"
                      >
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Vous devez saisir un numéro de téléphone correct.
                      </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              </>
            ) : null}
            <br/>
            <Form.Check type="switch" required
            checked={personnaldata}
            onClick={() => toggleField(setPersonnalData)}
            onChange={(e) => setDevis({ ...devis, personnaldata: e.target.checked }) }
            feedback="Vous devez accepter de transmettre les données saisies avant de soumettre votre demande"
            feedbackType="invalid"
            label={<small className="text-muted">
            En cochant cette case, j'accepte de partager mes données personnelles avec la société CAP TRAVEL, éditrice du service www.autocar-avec-chauffeur.com.
            Ces données seront utilisées par CAP TRAVEL pour répondre à la demande effectuée.
            Elles seront conservées par CAP TRAVEL le temps nécessaire pour le besoin de la relation commerciale.
            Je conserve un droit d'accès et de rectification, un droit à l'effacement et à la limitation du traitement sur ces données.
            Pour avoir plus d'information sur notre politique de protection des données personnelles, merci de consultez notre page
            <a className="text-muted" onClick={(e:any) => {Createlink('entreprise/mentions-legales')}}> Mentions légales</a>
          </small>}
            />
            <br/>
          </Modal.Body>

          <Modal.Footer className="bg-secondary justify-content-between">

            <Button variant="secondary" onClick={() => toggleField(setSubmittedCover)}>
              Fermer
            </Button>
            <Button variant="kease" type="submit">
              Envoyer
            </Button>
          </Modal.Footer>

        </Form>
      </Modal>

      {typetrajet !== 'Séjour/Evènement' && (
        <>
        <Modal show={submitteddevis} onHide={() => toggleField(setSubmittedDevis)}>
        <Modal.Header closeButton className="bg-kease">
            <Modal.Title>Votre devis enregistré</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <p>Votre devis vient de vous être envoyé par mail.</p>
              <p>Pensez à vérifier vos spams, on ne sait jamais...</p>
          </Modal.Body>
        </Modal>
      </>
      )}

      {typetrajet === 'Séjour/Evènement' && (
        <>
        <Modal show={submitteddevis} onHide={() => toggleField(setSubmittedDevis)}>
        <Modal.Header closeButton className="bg-kease">
            <Modal.Title>Votre demande est envoyée</Modal.Title>
        </Modal.Header>
          <Modal.Body>
              <p>Nous avons bien reçu votre demande.</p>
              <p>Un conseiller fait le point sur votre demande et vous appelle dès qu'il est disponible.</p>
          </Modal.Body>
        </Modal>
      </>
      )}

    </Container>
    </>
  );
}

export default DevisCover;


