import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import '../../index.css';
import {ReactComponent as ReactLogo} from '../../assets/logo/logo.svg';
import { useLinkNavigation } from './MenuFonctions';
import LinkPublicMenu from './LinkPublicMenu';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { useEffect, useState } from 'react';

const PublicMenu: React.FC=  () => {
    const { expanded, expandedMenu, toggleExpanded, toggleExpandedMenu, createLink} = useLinkNavigation();

    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
      };

    let direction: DropDirection;
    if (window.innerWidth < 1000) {
        direction = 'up';
    } else {
        direction = 'down';
    };

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    return (
        <>
        <Navbar expanded={expanded} onToggle={toggleExpanded} className="color-white mt-kease" fixed="top" expand="xl">
            <Container fluid>
                <Navbar.Brand className="app-header-logo"  onClick={() => handleClick("")}>
                    <ReactLogo className="bi bi-cloud-check" width="70" height="70"/>{' '}
                </Navbar.Brand>
                <Navbar.Toggle/>
                {expandedMenu && 
                        <LinkPublicMenu onExpandedChange={toggleExpanded}/>
                    }

                    {!expandedMenu && !isSmallScreen &&
                        <LinkPublicMenu onExpandedChange={toggleExpanded}/>
                }

                <Navbar.Collapse className="mx-auto justify-content-end">
                    <Nav>
                        <Button onClick={() => handleClick("login")} className="border-0 btn btn-kease m-3" >
                            Se&nbsp;connecter
                        </Button>
                        <br className="d-none d-xs-none d-md-none"/>
                        <Button onClick={() => handleClick("create-account")} className="border-0 btn btn-kease m-3">
                            S'inscrire
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );

};

export default PublicMenu


