import React from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import ReactTarmac from '../../assets/services/tarmac.svg';
import ReactMeeting from '../../assets/services/meeting.svg';
import ReactVisitedeSite from '../../assets/services/visitedesite.svg';
import ReactSeminaires from '../../assets/services/seminaire.svg';
import ReactNavettes from '../../assets/services/navette.svg';
import DevisCover from './DevisCover';

const Services: React.FC = () => {

    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
    }


  return (
    <>
        <h1 className="bg-dark text-center m-0">Autocar avec chauffeur | Location d'autocar avec chauffeur</h1>
        <br />
        <p className='container'>
            Allier <strong>qualité de prestation de transport et le tarif le plus juste</strong> :
            voici le défi relevé par les fondateurs de Autocar avec Chauffeur pour <strong>assurer vos projets de déplacements</strong>.
            Cap Event se forge d'une équipe d'<strong>experts dans le transport de personnes</strong>
            qui mettra l'ensemble votre service en place.
            L'équipe saura vous recommander les meilleures options afin de vous apporter sereinité
            et tranquilité pour l'organisation de votre évènement, tel qu'il soit.
            Nous apportons des solutions <strong>sur-mesure</strong> et <strong>adaptées à vos besoins</strong>.
            Nous entrenons une relation de <strong>proximité</strong> et de <strong>confiance</strong> avec nos clients.
            Nous ne laissons pas de place aux incertitudes, <strong>anticipation et agilité</strong> sont notre leitmotiv !
            <br />
        </p>
        <div className="cover-index d-flex align-items-center">
            <DevisCover/>
        </div>
        <Container fluid>
   
        <br/>
        <br/>
        <Row xs={1} md={2} lg={3} xl={5} className="mx-auto d-flex justify-content-center">

            <Card className="features card border-0">
                <div className="text-center " onClick={(e:any) => {Createlink('transfert-aeroport-tranfert-gare')}}>
                    <img src={ReactTarmac} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Transferts Aéroports<br/> Tranferts Gares</h5>
                        <p className="card-text text-left">
                            Avec les transferts d'aéroport de Autocar avec Chauffeur, vous pouvez rejoindre et être récupéré à l'aéroport ou à la gare
                            de votre choix dans un <strong>confort total</strong>.
                            Notre chauffeur vous attendra dans le hall d'acceuil avec une pancarte au nom de votre groupe.
                            Nous disposons des habilitations pour vous récuperer ou vous déposer sur le tarmac.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('seminaires-soiree-entreprise')}}>
                    <img src={ReactMeeting} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Séminaires<br/>Soirées d'entreprise</h5>
                        <p className="card-text text-left">
                            Acheminer chacun des participants à votre évènement, organisateurs, intervenants exterieurs,
                            depuis son lieu de résidence habituel vers le lieu que vous avez choisi
                            dans les meilleures conditions de ponctualité, de sécurité et de confort, pour un coût maîtrisé.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('visite-de-site-usine-entreprise')}}>
                    <img src={ReactVisitedeSite} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Coordination<br />sur place</h5>
                        <p className="card-text text-left">
                            Formule la plus courante, les visites d’entreprises consistent à proposer un
                            parcours de visite permettant de découvrir tout ou partie des activités de votre
                            entreprise.
                            Nous vous accompagnons dans la mise en place de la logistique de cette visite.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('salon-foire-exposition')}}>
                    <img src={ReactSeminaires} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Devis<br />immédiat</h5>
                        <p className="card-text text-left">
                            Spécialiste de la logistique transport de l’événement, Autocar avec Chauffeur vous <strong>accompagne sur place</strong> lors
                            de vos événements commerciaux et culturels.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('mise-en-place-de-navettes')}}>
                    <img src={ReactNavettes} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Sécurité<br />et garanties</h5>
                        <p className="card-text text-left">
                            Pour permettre l'activité de votre entreprise et garantir la protection de vos salariés,
                            nous vous mettons en place des navettes désinfectées afin de prendre en charge vos employés et
                            de les déposer sur leur site de travail en toute sécurité.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

        </Row>
        <br/>

        </Container>
        <br/>
        <br/>
    </>
  );
};

export default Services