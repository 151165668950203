import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DevisAR from './DevisAR';
import DevisAS from './DevisAS';

const Devis: React.FC = () => {
    const { uid } = useParams();
    const navigate = useNavigate();
    const [devis, setDevis] = useState<any>(null);

    async function loadDevis(requestData: { uid: any; database: any; }) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVEURADRESS}/firebase/getDataByUidFirebase?uid=${requestData.uid}&database=${requestData.database}`);

            if (!response.ok) {
                throw new Error('La requête a échoué');
            }
            const fetchedDevis = await response.json(); // Attendre la résolution de la promesse
            if (!fetchedDevis) {
                // Redirigez ou affichez un message d'erreur si le devis n'est pas trouvé
                navigate('/gestion-devis', { replace: true });
            } else {
                setDevis(fetchedDevis);
                const today = new Date();
                await setDevis({...fetchedDevis, datedevis: today.toISOString()})
            }
        } catch (error) {
          console.error('Error loading data:', error);
        }
    }

    useEffect(() => {
        const requestData = {
        uid: uid,
        database: 'devis',
        };
        loadDevis(requestData);
    }, [uid, navigate]);

    if(!devis) {
        return null;
    }
    if (devis.typetrajet === 'Aller-retour') {
        return <DevisAR devis={devis} setDevis={setDevis}/>;
    } else if (devis.typetrajet === 'Aller simple') {
        return <DevisAS devis={devis} setDevis={setDevis}/>;
    } else {
        navigate('/');
        return null;
    }
};

export default Devis;
