import { Button, Card, Col, Form } from "react-bootstrap";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { ref, onValue, update } from "firebase/database";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../utils/usercontext";
import { toggleField } from "../../utils/globalfunctions";

type UserProfil = {
  firstname: string;
  lastname: string;
  entity: string;
  phone: string;
  firstnameold?: string;
  lastnameold?: string;
  entityold?: string;
  phoneold?: string;
};

const Profil: React.FC = () => {
  const [userProfil, setUserProfil] = useState<UserProfil>({
    firstname: "",
    lastname: "",
    entity: "",
    phone: "",
  });

  useEffect(() => {
    if (auth.currentUser) {
      onValue(ref(dbCollection, 'users/' + auth.currentUser.uid), snapshot => {
        setUserProfil(snapshot.val());
      });
    }
  }, [auth.currentUser, setUserProfil]);

  const { setUserProfile } = useContext(UserContext);

  function updateUserProfile() {
    if (auth.currentUser) {
      onValue(ref(dbCollection, 'users/' + auth.currentUser.uid), snapshot => {
        setUserProfile(snapshot.val());
      });
    }
  };

  const navigate = useNavigate();
  const firstnameRef = useRef<HTMLInputElement | null>(null);
  const lastnameRef = useRef<HTMLInputElement | null>(null);
  const phoneRef = useRef<HTMLInputElement | null>(null);

  const [isFirstname, setFirstname] = useState(false);
  const [isLastname, setLastname] = useState(false);
  const [isPhone, setPhone] = useState(false);

  const updateField = async (
    field: keyof UserProfil, // Utilisez keyof pour indiquer les clés valides
    newValue: string | null,
    Ref: React.MutableRefObject<HTMLInputElement | null>,
    toggleFunc: (value: boolean) => void
  ) => {
    if (!auth.currentUser || !newValue || !Ref.current) {
      return;
    }

    const updateData: Partial<UserProfil> = {
      [`${field}old`]: userProfil[field],
      [field]: Ref.current.value,
    };

    await update(ref(dbCollection, `users/${auth.currentUser.uid}`), updateData);
    toggleFunc(false);
    updateUserProfile();
    navigate("/user-profile");
  };

  return (
    <>
      <Card className="border-0 mx-auto">
        <Card.Body>
          <h2 className="text-center mb-4 text-kease">Profil</h2>

          <Form className="border-0 mx-auto">

            <Form.Group className="mb-3 row" id="firstname" onClick={() => toggleField(setFirstname)}>
              <Col xs={4}>
                <strong>Prénom :</strong>
              </Col>
              <Col xs={7}>
                <strong style={{ display: (userProfil.firstname ? 'block' : 'none') }}>{userProfil.firstname}</strong>
              </Col>
              <Col xs={1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002FA7" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </Col>
            </Form.Group>
            {isFirstname && (<Form.Group className="mb-3 row">
              <Col xs={6}>
                <Form.Control type="string" ref={firstnameRef} placeholder="Prénom" required />
              </Col>
              <Col xs={6}>
                <Button className="text-center btn-kease w-100" onClick={() => { updateField("firstname", userProfil.firstname, firstnameRef, setFirstname) }}>
                  Valider
                </Button>
              </Col>
            </Form.Group>)}

            <Form.Group className="mb-3 row" id="lastname" onClick={() => toggleField(setLastname)}>
              <Col xs={4}>
                <strong>Nom :</strong>
              </Col>
              <Col xs={7}>
                <strong style={{ display: (userProfil.lastname ? 'block' : 'none') }}>{userProfil.lastname}</strong>
              </Col>
              <Col xs={1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002FA7" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </Col>
            </Form.Group>

            {isLastname && (<Form.Group className="mb-3 row">
              <Col xs={6}>
                <Form.Control type="string" ref={lastnameRef} placeholder="Nom" required />
              </Col>
              <Col xs={6}>
                <Button className="text-center btn-kease w-100" onClick={() => { updateField("lastname", userProfil.lastname, lastnameRef, setLastname) }}>
                  Valider
                </Button>
              </Col>
            </Form.Group>)}

            <Form.Group className="mb-3 row" id="phone" onClick={() => toggleField(setPhone)}>
              <Col xs={4}>
                <strong>Téléphone :</strong>
              </Col>
              <Col xs={7}>
                <strong style={{ display: (userProfil.phone ? 'block' : 'none') }}>{userProfil.phone}</strong>
              </Col>
              <Col xs={1}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#002FA7" className="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </Col>
            </Form.Group>
            {isPhone && (<Form.Group className="mb-3 row">
              <Col xs={6}>
                <Form.Control type="string" ref={phoneRef} placeholder="Téléphone" required />
              </Col>
              <Col xs={6}>
                <Button className="text-center btn-kease w-100" onClick={() => { updateField("phone", userProfil.phone, phoneRef, setPhone) }}>
                  Valider
                </Button>
              </Col>
            </Form.Group>)}

            <br />

          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2"></div>
    </>
  )
}

export default Profil;
