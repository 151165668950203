import { Button, ButtonGroup, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { dbCollection } from "../../../utils/firebaseconfig";
import { ref, onValue, update, off, get } from "firebase/database";
import { useEffect, useState } from "react";
import { formatDateLong } from "../../../utils/globalfunctions";

interface TarifsData {
  key: string;
  termefixe: number;
  tarifkm: number;
  salaire: number;
  vehicule: string;
}

const Gestiontarif: React.FC = () => {
    const [tarifs, setTarifs] = useState<TarifsData[]>([]);
    const [AverageSalaire, setAverageSalaire] = useState(0);
    const [newAverageSalaire, setNewAverageSalaire] = useState(AverageSalaire);

    useEffect(() => {
        const tarifRef = ref(dbCollection, "tarification");

        const fetchtarifsData = async (snapshot: any, settarifsData: (data: TarifsData[]) => void) => {
            const TarifsData: TarifsData[] = [];
            let TotalSalaire =0;
            let nbvehicule = 0;
            snapshot.forEach((childSnapshot: any) => {
                const tarif = childSnapshot.val();
                nbvehicule++;
                TotalSalaire = TotalSalaire + tarif.salaire;
                TarifsData.push({
                key: childSnapshot.key,
                termefixe : tarif.termefixe,
                vehicule: tarif.vehicule,
                tarifkm: tarif.tarifkm,
                salaire: tarif.salaire,
                });
            });
            
            setTarifs(TarifsData);
            if (nbvehicule > 0) {
                const averageSalaire = TotalSalaire / nbvehicule;
                setAverageSalaire(averageSalaire);
              } else {
                setAverageSalaire(0);
            }
        };

    const tarifRefOff = onValue(tarifRef, (snapshot) => {
      fetchtarifsData(snapshot, setTarifs);
    });
    return () => {
      off(tarifRef, "value", tarifRefOff);
    };

  }, []);

  const handleValueChange = (key: string, field: string, newValue: any) => {
    // Créez un objet pour les données mises à jour
    const updatedData = {
      [field]: newValue,
    };
  
    // Mettez à jour la base de données Firebase
    const tarifRef = ref(dbCollection, "tarification/" + key); // Référence au tarif spécifique dans la base de données
    update(tarifRef, updatedData)
      .then(() => {
        // Si la mise à jour est réussie, mettez également à jour l'état local (si nécessaire)
        const updatedTarifs = tarifs.map((tarif) => {
          if (tarif.key === key) {
            return { ...tarif, [field]: newValue };
          }
          return tarif;
        });
        setTarifs(updatedTarifs);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour de la base de données :", error);
        // Vous pouvez gérer l'erreur ici, par exemple en affichant un message à l'utilisateur
      });
  };
  
  const handleSalaireChange = async () => {
    // Référence à la collection "tarification" dans Firebase
    const tarifRef = ref(dbCollection, "tarification");
    const tarifSnapshot = await get(tarifRef);
    
    tarifSnapshot.forEach((tarifChildSnapshot: any) => {
        handleValueChange (tarifChildSnapshot.key, "salaire", newAverageSalaire);
   });
};
 
  return (
    <>
    <h1 className="bg-dark text-center m-0">Gestion des tarifs</h1>
    <Container>
        <br/>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column sm="4">Salaire moyen </Form.Label>
            <Form.Label column sm="2">{AverageSalaire.toFixed(2)}</Form.Label>
            <Col sm="2">
            <Form.Control
                className="text-end"
                type="number"
                placeholder="Nouveau salaire"
                onChange={(e) => {
                    const newValue = parseFloat(e.target.value); // Convertissez la valeur en nombre
                    if (!isNaN(newValue)) {
                        setNewAverageSalaire(newValue); // Mettez à jour newAverageSalaire avec le nombre
                    }
                }}
            />
            </Col>
            <Col sm="4">
            <Button variant="primary" onClick={handleSalaireChange}>
              Valider
            </Button>
          </Col>
        </Form.Group>
        <Table>
            <thead>
                <tr>
                <th>Véhicule</th>
                <th className="text-end">Terme fixe</th>
                <th className="text-end">Tarif au km</th>
                </tr>
            </thead>
            <tbody>
                {tarifs.map((tarif) => (
                <tr key={tarif.key}>
                    <td>{tarif.vehicule} - {tarif.key} pax</td>
                    <td className="text-end">
                    <Form.Control
                        className="text-end"
                        type="number"
                        value={tarif.termefixe}
                        onChange={(e) => handleValueChange(tarif.key, "termefixe", e.target.value)}
                    />
                    </td>
                    <td className="text-end">
                    <Form.Control
                        className="text-end"
                        type="number"
                        value={tarif.tarifkm}
                        onChange={(e) => handleValueChange(tarif.key, "tarifkm", e.target.value)}
                    />
                    </td>
                    <td className="text-end">
                    <Form.Control
                        className="text-end"
                        type="number"
                        value={tarif.salaire}
                        onChange={(e) => handleValueChange(tarif.key, "salaire", e.target.value)}
                    />
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
        <br/>
    </Container>

    </>
  );
};

export default Gestiontarif;
