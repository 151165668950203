import { Key, useEffect, useState } from "react";
import DevisCover from "../onepage/DevisCover";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const Region: React.FC<{ region: any}> = ({ region }) => {
    const isVowel = (char: string) => {
        const normalizedChar = char.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return ['a', 'e', 'i', 'o', 'u', 'y'].includes(normalizedChar);
    };
    const startsWithVowel = isVowel(region.name);

    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
    }
    const [autresregions, setAutresRegions] = useState<any>([]);
    const [tables, setTables] = useState<any>([]);

    function CreatelinkRegion(linky:any, num_region: string){
        navigate('/' + linky, { replace: true });
        loadRegionRoutes(num_region);
        window.scrollTo(0, 0);
    }

    const loadRegionRoutes = (num_region: string) => {
        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRegionRoutes?nomdufichier=referencement&zipcode=${num_region}`)
            .then(response => response.json())
            .then(tables => setTables(tables))
    };

    function capitalizeWords(str:string) {
        return str
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }

    useEffect(() => {
        loadRegionRoutes(region.region_name);

        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=regions`)
          .then(response => response.json())
          .then(autresregions => setAutresRegions(autresregions))
      }, []);

    return (
        <>
        <div className="cover-index d-flex align-items-center">
            <DevisCover />
        </div>
        <div className="bg-light d-flex align-items-center justify-content-center">
            <p onClick={(e:any) => {Createlink('')}}>Accueil </p>
            <p className="mx-3">&gt;</p>
            <p onClick={(e:any) => {Createlink('location-autocar-'+region.region_name)}}>
                {region.name}
            </p>
        </div>
        <div className="text-center">
            <h1 className="bg-dark">Devis de location d’autocar au départ {region.type}{startsWithVowel? '' : ' '}{region.name}</h1>
        </div>
        <div className="text-center">
            Service de location d’autocar avec chauffeur au départ {region.type}{startsWithVowel ? '' : ' '}{region.name}.<br/>
            Sur le département {region.type}{startsWithVowel ? '' : ' '}{region.name} nous disposons d’une flotte d’autocars et de bus qui nous permettent de répondre à vos besoins de transports.
        </div>
        <br />
        <h2 className="text-center"><small>Nos dernières demandes au départ {region.type}{startsWithVowel ? '' : ' '}{region.name} par ville par ordre alphabétique</small></h2>
        <Table>
            <tbody>
                {tables
                    .sort((a: any, b: any) => a.villego.label.localeCompare(b.villego.label))
                    .map((table: any, index: Key) => (
                    <tr key={index}>
                        <td onClick={(e:any) => {Createlink('location-autocar-'+table.villego.label+'-'+table.villego.zip_code)}}>{capitalizeWords(table.villego.label)} ({table.villego.department_number})</td>
                        <td onClick={(e:any) => {Createlink('location-autocar-'+table.villeaway.label+'-'+table.villeaway.zip_code)}}>{capitalizeWords(table.villeaway.label)} ({table.villeaway.department_number})</td>
                        <td className="text-end">{table.nbrepax} {table.type}</td>
                        <td className="text-end">{table.vehicule.nbrevehicule} {table.vehicule.vehicule}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <br />
        <div className="bg-light">
            <br />
            <h2 className="text-center"><small>Locations d'autocars au départ des autres régions de France</small></h2>
            <br />
            <Row xs={3} md={6} className="mx-auto d-flex justify-content-center container">
                {autresregions
                    .filter((autreregion: any) => autreregion.region_name !== region.region_name)
                    .map((autreregion: any, index: Key) => (
                        <Card key={index} className="bg-light border-0 text-center mb-3" onClick={(e:any) => {CreatelinkRegion('location-autocar-'+autreregion.region_name, autreregion.region_name)}}>{autreregion.name}</Card>
                    ))}
            </Row>
            <br />
        </div>
        </>
    );
}

export default Region;
