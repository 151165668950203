import { useEffect, useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';

const Reglementation: React.FC = () => {

    const [isMenuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }, []);
  
    const handleMenuLinkClick = (event : any) => {
      event.preventDefault(); // Empêche le comportement par défaut du lien
    
      const targetSectionId = event.target.getAttribute('href'); // Obtient l'ID de la section ciblée
      const targetSection = document.querySelector(targetSectionId); // Sélectionne la section ciblée
    
      if (targetSection) {
        const offset = targetSection.offsetTop - 100; // Calcule le décalage en soustrayant 100 pixels
        window.scrollTo({ top: offset, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
      }
    };

  return (
    <>
    <div className="text-center">
      <h1 className="bg-dark">Réglementation du transport de personnes</h1>
    </div>
    
    <Row>
        <Col className="col-3 m-3">
            <Nav className="custom-sticky-top border border-1 border-kease rounded-3">
                <ul className="list-unstyled">
                <li>
                    <p className="fw-bold m-2 ps-2">Table des matières</p>
                </li>
                <li>
                    <Nav.Link href="#article1" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                        Article 1 - L'amplitude ou durée de travail maximum journalière
                    </Nav.Link>
                </li>
                <li>
                    <Nav.Link href="#article2" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                        Article 2 - La durée du temps de conduite
                    </Nav.Link>
                </li>
                <li>
                    <Nav.Link href="#article3" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                        Article 3 - Temps de pause ou temps d'arrêt
                    </Nav.Link>
                </li>
                <li>
                    <Nav.Link href="#article4" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                        Article 4 - Temps de repos journalier
                    </Nav.Link>
                </li>
                <li>
                    <Nav.Link href="#article5" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                        Article 5 - Le repos hebdomadaire
                    </Nav.Link>
                </li>
                </ul>
            </Nav>
        </Col>
        <Col className="about__info mt-3">
        <p>
            La règlementation du transport de personnes est l'affaire de tous afin de garantir la sécurité des passagers et du personnel roulant.
            Ne pas respecter cette réglementation revient à faire prendre des risques inconsidérés aux passagers et aux conducteurs.
            Le respect de cette <strong>réglementation</strong> est au cœur des préoccupations de Cap Event dans la <strong>gestion de la logistique </strong> 
            des opérations de transports et dans le <strong>conseil </strong> apportés à nos clients.
        </p>
        <p><em>Date de dernière mise à jour : 26 mai 2023</em></p>
        <br/>
        <section id="article1">
            <h5 className="fw-bold">Article 1 - L'amplitude ou durée de travail maximum journalière</h5>
            <ul>
                <li>
                    L’amplitude de la journée de travail est l’intervalle entre deux repos, soit entre
                    le début de journée de travail et la fin de celle-ci.
                </li>
                <li>L’amplitude de la journée de travail ne doit pas dépasser 12 heures (article R. 3312-9 du code des transports).</li>
                <li>
                    En cas de d'amplitude supérieure pour les besoins de déplacement, il est possible de
                    mettre à disposition un deuxième chauffeur afin de porter une amplitude totale des deux chauffeurs à 18h.
                </li>
            </ul>
        </section>
        <section id="article2">
            <h5 className="fw-bold">Article 2 - La durée du temps de conduite</h5>
            <ul>
                <li>La durée de conduite journalière est limitée à 9 heures pouvant être portée à 10 heures deux fois par semaine.</li>
                <li>La durée de conduite hebdomadaire est limitée à 56 heures et 90 heures sur deux semaines consécutives.</li>
            </ul>
        </section>
        <section id="article3">
            <h5 className="fw-bold">Article 3 - Temps de pause ou temps d'arrêt</h5>
            <ul>
                <li>Le temps de conduite ne doit pas excéder 4h30 durant la journée (entre 06h00 et 21h00).</li>
                <li>Le temps de conduite ne doit pas excéder 4h00 entre 21h00 et 06h00 du matin.</li>
                <li>
                    Au delà de ce temps de conduite, le chauffeur doit prendre une pause d'au moins 45min.
                    Cet arrêt peut être fractionné en deux, la première étant de 15min et la seconde de 30min.
                </li>
            </ul>
        </section> 
        <section id="article4">
            <h5 className="fw-bold">Article 4 - Temps de repos journalier</h5>
            <ul>
                <li>
                    Le temps de repos est d’au moins 11 heures :
                    <ul>
                        <li>avec possibilité de fractionnement en une première tranche de 3 heures et deuxième tranche de 9 heures</li>
                        <li>trois fois maximum entre deux temps de repos hebdomadaires, ce temps de repos peut -etre réduit à 9 heures</li>
                    </ul>
                </li>
            </ul>
        </section> 
        <section id="article5">
            <h5 className="fw-bold">Article 5 - Le repos hebdomadaire</h5>
            <ul>
                <li>La durée de repos hebdomadaire doit être égale à 45h consécutives.</li>
                <li>Il est possible de réduire le repos à 24h sur deux semaines consécutives sous condition de rattrapage avant la fin de la troisième semaine</li>
            </ul>
        </section> 
        
        </Col>
    </Row>
    <p>
        <strong>Plus d’information sur le transport routier de voyageurs :</strong>
        <a title="Législation transport routier de voyageur" href="http://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000871783&amp;fastPos=1&amp;fastReqId=1624323283&amp;categorieLien=cid&amp;oldAction=rechTexte"
            target="_blank" rel="noopener noreferrer">Décret n°85-891 du 16 août 1985 relatif aux transports urbains de personnes et aux transports routiers non urbains de personnes</a>
        (Version consolidée au 11 juillet 2014)
    </p>

      
    </>
  );
}

export default Reglementation;
