import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const MentionsLegales: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }, []);
  return (
    <>
    
    <div className="justify-content-center">
        <h1 className="bg-dark text-center m-0">Mentions légales</h1>
    </div>
    <br/>
    <div className="container">
        <h4>Editeur</h4>

        <p>
            www.autocar-avec-chauffeur.com est une marque de CAP TRAVEL
        </p>
        <p>
            société par actions simplifiée au capital de 5.000 euros,
        </p>
        <p>
            dont le siège social est sis au 12 rue Traversière à Boulogne-Billancourt (92100),
        </p>
        <p>
            enregistrée auprès du Registre du Commerce et des Sociétés de Nanterre, sous le numéro 882 372 204.
        </p>
        <h4>Conditions générales d'utilisation</h4>
        <p>
            La société CAP TRAVEL (ci-après la « Société ») a fait le choix d’éditer, animer et mettre à disposition le site internet www.capevent.fr (ci-après le « Site »),
            ayant pour objectif principal de communiquer des informations sur ses activités et actualités.
        </p>
        <p>
            Les présentes conditions générales de ventes (ci-après les « CGV ») ont pour objet de définir les conditions dans lesquelles,
            les utilisateurs sont habilités à se connecter au Site.
            Tout accès au Site entraînant automatiquement l’application des CGV, l’utilisateur est réputé avoir lu et accepté sans réserve les CGV lorsqu’il consulte sur le Site.
        </p>
        <p>
            Les CGV applicables sont celles en vigueur à la date de chaque connexion de l’utilisateur au Site.
            Il est précisé que la Société reste libre, à tout moment d'enrichir, modifier, corriger ou supprimer tout ou partie des CGV.
            Ces modifications seront considérées comme ayant été portées à la connaissance des utilisateurs du simple fait de leur mise en ligne.
            Dès lors, elles sont réputées acceptées sans réserve par tout utilisateur accédant au Site postérieurement à leur mise en ligne.
            Par conséquent, l’utilisateur est invité à s’y référer lors de chaque visite afin de prendre connaissance de leur dernière version disponible sur le Site.

        </p>
        <h4>Accès au site</h4>
        <p>
            Toute personne physique ou morale peut accéder au Site.
        </p>
        <p>
            Autocar-avec-chauffeur se réserve le droit de modifier, de suspendre, de restreindre ou d'interrompre l'accès à tout ou partie du Site,
            en ce compris l’accès à son contenu et à ses fonctionnalités, ou encore les heures de disponibilité du Site, et ce, sans préavis.
        </p>
        <h4>Propriété intellectuelle</h4>
        <p>
            Le Site et tous les éléments qui le constituent (notamment les textes, présentations, vidéos, photographies, les documents téléchargeables, marques et logos...),
            les logiciels rattachés, sont protégés par des droits de propriété intellectuelle et des droits voisins, réservés aux seuls titulaires de ces droits.
        </p>
        <p>
            Sauf dans les cas permis par Cap Event, et à l'exception notamment des reproductions sans modifications ni altérations faites à des fins de copie personnelle
            et privée conformément à l'article L.122-5, 2° du Code de la propriété intellectuelle, toute utilisation, exploitation,
            reproduction, représentation, diffusion,
            édition ou publication, partielle ou totale, du Site et/ou des éléments qui le constituent, sous quelque forme/format que ce soit,
            sur quelque support que ce soit et pour quelque finalité que ce soit (commerciale, publicitaire…) est strictement interdite et sanctionnée par la loi.
        </p>
        <h4>Droits d'auteur</h4>
        <p>
            Les obligations mentionnées au paragraphe précédent ne s’appliquent pas aux journalistes et éditeurs de presse
            uniquement pour ce qui est des droits d’auteur
            relatif au contenu présent au sein des onglets « communiqués de presse » et « médiathèque ».
            La Société met à leur disposition des documents iconographiques et dossiers de presse qu’ils peuvent utiliser, reproduire, représenter, diffuser,
            éditer et publier, pour illustrer leurs articles et travaux de presse, sous réserve que soient indiqués clairement le nom de l'auteur, si ce dernier est disponible, et la source.
        </p>
        <h4>Données personnelles</h4>
        <p>
            Au sein de la rubrique intitulée « Etablir un devis » ou « Nous contacter » ,
            l’utilisateur doit communiquer les données nécessaires à la prise en compte et au traitement de sa demande.
            L’utilisateur s'engage à communiquer des informations exactes.
        </p>
        <p>
            Conformément à la règlementation applicable, l'utilisateur dispose d’un droit d’accès, de rectification, d’effacement
            de portabilité et de limitation de vos données personnelles.
            Ainsi qu’un droit de retrait de votre consentement pour transmettre votre demande à la société Cap Event.
            Vous pouvez exercer ces droits auprès de notre Délégué à la Protection des données à l’adresse suivante : <a href="mailto:data.protection@capevent.fr"><strong>data.protection@capevent.fr</strong></a>.
        </p>
        <p>
            En aucun cas, les données recueillies sur le Site ne seront transmises, cédées ou vendues à des tiers,
            en ce compris nos partenaires, sans l’accord exprès et préalable de l’utilisateur.
        </p>
        <p>
            Pour plus d'informations, veuillez vous référer à la <a className="link-in-text" asp-area="" asp-controller="entreprise" asp-action="confidentialite"><strong>politique de confidentialité</strong></a> du Site.
        </p>
        <h4>Cookies</h4>
        <p>
            Pour plus d'informations, veuillez vous référer à la <a className="link-in-text" asp-area="" asp-controller="entreprise" asp-action="confidentialite"><strong>politique de gestion des cookies</strong></a> du Site.
        </p>
        <h4>Liens hypertextes</h4>
        <p>
            La mise en place d'un lien hypertexte vers le Site nécessite une autorisation expresse et préalable de Cap Event.
            Toute demande de mise en place d’un lien hypertexte vers le Site doit être adressée via le formulaire de contact, au sein de l’onglet intitulé « Nous contacter ».
        </p>
        <p>
            Le Site permet aux utilisateurs d’être redirigés vers des sites internet et services de tiers via un lien hypertexte à partir du Site,
            notamment via des boutons (twitter, YouTube…). Les utilisateurs peuvent ainsi visualiser, par exemple, les Tweets publiés sur le compte &#64;CapEvent sur Twitter.
        </p>
        <p>
            L’utilisateur reconnaît que la Société est indépendante de ces sites internet et services fournis par des tiers.
            Toute utilisation de ces services sera soumise aux conditions d’utilisation et/ou de vente propres à chaque tiers offrant ces services.
            La responsabilité de la Société ne saurait être recherchée du fait du contenu accessible, de l’ensemble des offres,
            informations consultées ou transactions réalisées via les services offerts par des tiers.
            De la même façon, Cap Event n’offre aucune garantie quant au respect de la réglementation applicable par les éditeurs et hébergeurs de ces services dont ils sont seuls responsables.
        </p>
        <h4>Responsabilité</h4>
        <p>
            Malgré tous les soins apportés pour la constitution et la mise à jour du Site, des erreurs, inexactitudes ou omissions peuvent subsister.
            De plus, Cap Event s'engage à faire ses meilleurs efforts pour garantir l’accessibilité du Site, cependant des problèmes de connexion ou d'interruption dans la connexion au Site peuvent survenir.
            En effet, cette accessibilité peut être suspendue notamment pour des raisons de maintenance et/ou de qualité du réseau internet.
            La Société n’offre aucune garantie concernant les risques d’interruption ou de dysfonctionnement liés à la connexion,
            à l’encombrement des réseaux et/ou des systèmes informatiques, à l’intrusion de tiers non autorisés et
            à la contamination par d’éventuels virus circulant sur lesdits réseaux et/ou les systèmes informatiques.
            L'utilisateur utilise le Site à ses seuls risques.
            Cap Event décline toute responsabilité quant aux dommages directs ou indirects qui pourraient résulter d’une telle utilisation.
        </p>
        <p>
            Dans l’hypothèse où la responsabilité de la Société serait engagée, au titre d’un manquement aux obligations qui pèsent sur elle au titre des présentes CGV,
            la partie lésée pourra réclamer l’indemnisation intégrale de son préjudice tous dommages confondus,
            étant entendu que la Société ne sera pas responsable des dommages indirects, les parties s’en rapportant
            aux dispositions de l’article 1231-4 du Code civil en ce qui concerne leur qualification.
            En tous les cas, la responsabilité de la Société ne saurait être limitée et/ou exclue en cas de dommage corporel,
            faute lourde ou bien encore faute dolosive.
        </p>
        <h4>Droit applicable</h4>
        <p>
            Les présentes CGV sont soumises au droit français.
        </p>
        <p>
            Elles sont établies en Français, nonobstant toute traduction qui pourrait en être faite par ailleurs.
            En tout état de cause, la version en français des CGV primera sur toute autre version en langue étrangère.
        </p>
        <p>

            En cas de différend relatif à la validité, l'interprétation ou l'exécution des CGV, Cap Event et l’utilisateur
            conviennent de se rapprocher et de tenter de trouver une solution amiable à leur litige dans un délai de soixante (60) jours calendaires.
        </p>
        <p>
            En cas d’échec dans la recherche d’une solution amiable, tout différend relatif à l'interprétation, la validité,
            et/ou l'exécution des CGV sera soumis aux tribunaux compétents de Nanterre,
            y compris en cas de pluralité de défendeurs ou appel en garantie, pour les procédures tendant à obtenir des mesures d'urgence ou conservatoires,
            en référé ou sur requête.
        </p>
    
    </div>
    
    </>

    );
};

export default MentionsLegales;