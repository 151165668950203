import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ReactEcologie from '../../assets/entreprise/ecologie.svg';
import ReactSecurity from '../../assets/entreprise/seatbelt.svg';
import ReactConfort from '../../assets/entreprise/confort.svg';
import ReactTarif from '../../assets/entreprise/price.svg';

const AboutUs: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
}, []);

  return (
  <>
  <div className="justify-content-center">

    <h1 className="bg-dark rounded-top text-center">A propos de CAP TRAVEL</h1>
    <br/>
    <div className="container">
    <h2 >Une mobilité durable et intelligente pour découvrir le monde</h2>
    <p>
        CAP TRAVEL est un jeune opérateur de mobilité qui, depuis 2019, modifie la façon de voyager de millions de personnes.
        En effet, nous avons réussi à construire le plus important réseau de transport de personnes.
        Nous avons développé un système de réservation simplifiée afin de répondre efficacement et le plus rapidement possible à nos clients.
    </p>

    <h2>Les concepts de notre succès</h2>
    <p>
        Notre réseau est déjà développé en France, en Europe et dans les DOM-TOM et nous offrons à nos clients un transport alliant écologie, sécurité et confort au juste prix.
    </p>
    </div>
    <br/>
    <Row xs={1} md={2} lg={3} xl={5} className="justify-content-center">

        <Card className="border-0 m-3">
              <div className="text-center">
              <img src={ReactTarif} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body>
              <Card.Title>Tarif</Card.Title>
              <Card.Text>
                Parce qu'il ne faut pas choisir entre qualité ou tarif.
                Parce que nos clients ne doivent pas ne pas être en sécurité parce que le prix est intéressant.
                Nous vous garantissons des <strong>tarifs avantageux</strong> combinés avec un service optimum.
              </Card.Text>
            </Card.Body>
        </Card>

        <Card className="border-0 m-3">
              <img src={ReactSecurity} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              <Card.Body>
              <Card.Title>Sécurité</Card.Title>
              <Card.Text>
              Notre flotte de véhicules est récente et dispose des dernières technologies en terme de sécurité comme par exemple l'
                aide au changement de direction ou l'assistance préventive au freinage.
                Nos véhicules sont sûrs et bien entretenus, ils fonctionnent en tout temps au meilleur de leurs capacités pour maintenir,
                et améliorer, les excellentes performances des entreprises d’autobus et d’autocars en matière
                de sécurité routière.
                L'ensemble de nos chauffeurs suit les formations nécessaires respecte la législation afin de garantir la <strong>sécurité des passagers</strong>.
              </Card.Text>
            </Card.Body>
            </Card>

            <Card className="border-0 m-3">
              <img src={ReactConfort} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              <Card.Body>
              <Card.Title>Confort</Card.Title>
              <Card.Text>
                Le voyage en autocar est plus confortable qu'en voiture.
                En effet, le voyageur n'a qu'à se laisser transporter, c'est le chauffeur qui conduit.
                Il n'a pas de stress et est moins fatigué par son trajet.
                Le passager peut ainsi se reposer, lire, écouter de la musique, regarder des films ou séries, ou dormir.
                Les sièges sont inclinables, et certains autocars disposent d'un <strong>confort exceptionnel</strong>
                avec par exemple des salons, des reposes pieds, des prises pour charger son téléphone ou sa tablette.
              </Card.Text>
            </Card.Body>
            </Card>

            <Card className="border-0 m-3">
                <img src={ReactEcologie} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                <Card.Body>
                    <Card.Title>Ecologie</Card.Title>
                    <Card.Text>
                        L’autocar est un mode de transport en commun qui permet de réduire la circulation
                        des véhicules individuels et donc les émissions polluantes.
                        Par la formation de nos conducteurs à l’éco-conduite, la mise en place d’engagements
                        volontaires de <strong>réduction de CO2</strong> et de nombreuses innovations techniques concernant
                        les véhicules ainsi que grâce à l’arrivée de nouvelles énergies alternatives, des progrès
                        significatifs ont été réalisés concernant les émissions polluantes et les nuisances.
                        Nous nous efforçons de réduire l'impact sur le climat des déplacements de personnes.
                    </Card.Text>
                </Card.Body>
            </Card>


        </Row>
        <br/>
      </div>
    
    </>
  );
};

export default AboutUs;
