
// Fonction pour formater une date au format "dd/mm/yyyy"
export const formatDateShort = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    if (!date || date === 'Invalid Date') {
      return '-'; // Retourner une chaîne vide si la date est invalide ou non définie
    }
    return new Date(date).toLocaleDateString(undefined, options);
};

  
export const formatDateDevisDepart = (date: string) => {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  if (!date || date === 'Invalid Date') {
    return '-'; // Retourner une chaîne vide si la date est invalide ou non définie
  }
  return new Date(date).toLocaleDateString('fr-FR', options);
};

export const formatDateDevisArrivee = (date: string) => {
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
  if (!date || date === 'Invalid Date') {
    return '-'; // Retourner une chaîne vide si la date est invalide ou non définie
  }
  return new Date(date).toLocaleDateString('fr-FR', options).replace(' à', ' vers');
};

  // Fonction pour formater une date au format "dd mmmm yyyy"
export const formatDateLong = (date: string) => {
  if (!date || date === 'Invalid Date') {
    return '-'; // Retourner une chaîne vide si la date est invalide ou non définie
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long', // Utilisation de 'long' pour le mois en toutes lettres
    year: 'numeric',
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const toggleField = (toggleFunc: React.Dispatch<React.SetStateAction<boolean>>) => {
  return toggleFunc((prevState) => !prevState);
};
