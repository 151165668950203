import React from 'react';
import { Card, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ReactCapacite} from '../../assets/logo/capacite_transport_voyageur.svg';
import {ReactComponent as ReactGarantie} from '../../assets/logo/garantie_financiere.svg';

import {ReactComponent as ReactSepa} from '../../assets/payment/sepa.svg';
import {ReactComponent as ReactCreditCard} from '../../assets/payment/credit-card.svg';
import {ReactComponent as ReactCheque} from '../../assets/payment/cheque.svg';

import {ReactComponent as ReactFacebook} from '../../assets/social/facebook.svg';
import {ReactComponent as ReactX} from '../../assets/social/twitter.svg';
import {ReactComponent as ReactInsta} from '../../assets/social/instagram.svg';
import {ReactComponent as ReactLinkedIn} from '../../assets/social/linkedin.svg';

export const AppFooter = () => {
  const navigate = useNavigate();
    
  function Createlink(linky:any){
  navigate('/' + linky, { replace: true });
  }
    
  return (
    <>
      <div className="bg-dark">
        <Row xs={1} md={2} lg={3} xl={6} className="mx-auto d-flex justify-content-center">
          <Card className="border-0 bg-dark my-3">
                <div className="" >
                    <div className="card-body">
                        <h5 className="card-title">Qui sommes nous?</h5>
                        <ul className="list-unstyled">
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('entreprise/a-propos')}}>A propos de nous</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('entreprise/securite')}}>Sécurité</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('entreprise/conditions-generales-de-ventes')}}>Conditions générales</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('entreprise/mentions-legales')}}>Mentions légales</Nav.Link>
                          </li>
                        </ul>
                    </div>
                </div>
            </Card>
            <Card className="border-0 bg-dark my-3">
              <div className="" >
                <div className="card-body">
                    <h5 className="card-title">Autorisation de Transport</h5>
                    <ReactCapacite className="bi bi-cloud-check" width="150" height="150"/>{' '}
                </div>
              </div>
            </Card>
            <Card className="card border-0 bg-dark my-3">
              <div className="" >
                <div className="card-body">
                    <h5 className="card-title">Garanties</h5>
                    <ReactGarantie className="bi bi-cloud-check" width="150" height="150"/>{' '}
                </div>
              </div>
            </Card>
            <Card className="border-0 bg-dark my-3">
                <div className="" >
                    <div className="card-body">
                        <h5 className="card-title">Départs populaires</h5>
                        <ul className="list-unstyled">
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-paris')}}>Location Autocar Paris</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-ile-de-france')}}>Location Autocar Ile de France</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-marseille')}}>Location Autocar Marseille</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-lyon')}}>Location Autocar Lyon</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-bordeaux')}}>Location Autocar Bordeaux</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-nantes')}}>Location Autocar Nantes</Nav.Link>
                          </li>
                          <li>
                            <Nav.Link className="text-white p-0" onClick={(e:any) => {Createlink('location-autocar-strasbourg')}}>Location Autocar Strasbourg</Nav.Link>
                          </li>
                        </ul>
                    </div>
                </div>
            </Card>
            
            <Card className="border-0 bg-dark my-3">
              <div className="" >
                <div className="card-body">
                    <h5 className="card-title">Paiements</h5>
                    <span itemProp="payment" title="Réglez en toute sécurité par virement">
                      <ReactSepa className="payment text-light bi bi-cloud-check mx-1" width="40" height="50"/>{' '}
                      <ReactCreditCard className="payment text-light bi bi-cloud-check mx-1" width="30" height="50"/>{' '}
                      <ReactCheque className="payment text-light bi bi-cloud-check mx-1" width="30" height="50"/>{' '}
                    </span>
                    <ul className="list-unstyled text-light m-0">
                      <li>Virements</li>
                      <li>Cartes Bleues</li>
                      <li>Chèques</li>
                    </ul>
                </div>
              </div>
              <div className="" >
                <div className="card-body">
                    <h5 className="card-title">Suivez-nous</h5>
                    <span itemProp="payment" title="Réglez en toute sécurité par virement">
                      <ReactLinkedIn className="payment text-light bi bi-cloud-check mx-1" width="30" height="30"/>{' '}
                      <ReactFacebook className="payment text-light bi bi-cloud-check mx-1" width="30" height="30"/>{' '}
                      <ReactInsta className="payment text-light bi bi-cloud-check mx-1" width="30" height="30"/>{' '}
                      <ReactX className="payment text-light bi bi-cloud-check mx-1" width="30" height="30"/>{' '}
                    </span>
                </div>
              </div>
            </Card>
        </Row>
      </div>
      <br/>


      <Container fluid>
          <p className="w-100 text-center py-4"><small>
            Tous droits réservés &#169; {new Date().getFullYear()} www.autocar-avec-chauffeur.com ™ une marque de la société CAP TRAVEL
          </small></p>
        </Container>
    </>
  );
};
