import React, { useEffect } from 'react';

const Securite: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }, []);
  return (
    <>
    <div className="justify-content-center">
        <h1 className="bg-dark rounded-top text-center">La sécurité n'est pas une option mais une obligation</h1>
        <br/>
        <div className="container">
            <h2>Sécurité et fiabilité du départ à l'arrivée</h2>

            <p>
                Notre flotte de véhicules est récente et dispose des dernières technologies en terme de sécurité comme par exemple
                l' aide au changement de direction ou l'assistance préventive au freinage.
            </p>
            <p>
                Nos véhicules sont sûrs et bien entretenus, ils fonctionnent en tout temps au meilleur de leurs capacités pour maintenir,
                et améliorer, les excellentes performances des entreprises d’autobus et d’autocars en matière de sécurité routière.
            </p>
            <p>
                L'ensemble de nos chauffeurs suit les formations nécessaires respecte la législation afin de garantir la sécurité des passagers.
            </p>

            <h4>Nous misons sur l'intégration des fonctions de sécurité les plus récentes et sur la formation de nos conducteurs</h4>

            <p>
                La sécurité à bord est notre priorité, et cela passe aussi par la technologie.
            </p>
            <p>
                Nos bus sont équipés des derniers systèmes d'assistance électronique :
                ESP, assistance au freinage d'urgence, alerte de franchissement involontaire de ligne,
                radar de régulation de distance, capteur de lumière et feux latéraux,
                ainsi que d’autres dispositifs, sont une évidence à bord de tous les Cap Event.
            </p>
            <p>
                La sécurité de nos bus est également confirmée et ils sont régulièrement soumis à des contrôles appliquant les normes les plus élevées.
            </p>

            <h4>Nos conducteurs vous amènent à destination en toute sécurité - jour et nuit</h4>

            <p>
                Avec nos conducteurs, vous voyagez en toute sécurité.
            </p>
            <p>
                Tous reçoivent la meilleure formation et se préparent de façon optimale à toutes les situations routières lors de formations de sécurité régulières.
                Les temps de conduite et de pause sont clairement définis afin que votre conducteur soit toujours concentré au volant.
                La localisation par GPS nous permet de contrôler à tout moment les temps de conduite et de pause et l'emplacement de nos cars.
            </p>
            <p>
                Chez Cap Event, les trajets de nuit sont effectués par un double équipage avec un changement de conducteur régulier.
                Les conducteurs engagés sur les lignes de nuit sont spécialement formés à ces conditions.
                Même si cela peut rallonger votre voyage, la sécurité doit avoir la priorité !
            </p>

            <h4>Accrochez vos ceintures, c'est parti !</h4>

            <p>
                Avec nos conducteurs, vous voyagez en toute sécurité.
            </p>
            <p>
                Vous pouvez vous aussi contribuer à la sécurité en attachant votre ceinture, son port est obligatoire.
                Pour leur sécurité, nos jeunes passagers doivent, selon leur âge, être assis sur un siège enfant adéquat.
                Assurez-vous que votre enfant et vous êtes bien attachés pendant tout le trajet.
            </p>
            <p>
                Cap Event vous transporte de manière avec sécurité, réservez votre moyen de transport maintenant pour vous en convaincre.
            </p>

            <br/>
        </div>
    </div>
    </>
  );
};

export default Securite;
