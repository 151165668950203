import { Key, useEffect, useState } from "react";
import DevisCover from "../onepage/DevisCover";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Department: React.FC<{ departement: any}> = ({ departement }) => {
    const isVowel = (char: string) => {
        const normalizedChar = char.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return ['a', 'e', 'i', 'o', 'u', 'y'].includes(normalizedChar);
    };
    const startsWithVowel = isVowel(departement.name[0]);

    const navigate = useNavigate();
    
    function Createlink(linky:any){
        navigate('/' + linky, { replace: true });
    }
    
    const [autresdepartements, setAutresDepartements] = useState<any>([]);
    const [tables, setTables] = useState<any>([]);

    function CreatelinkDepartment(linky:any, num_dep: string){
        navigate('/' + linky, { replace: true });
        loadDepartmentRoutes(num_dep);
        window.scrollTo(0, 0);
    }

    const loadDepartmentRoutes = (num_dep: string) => {
        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createDepartmentRoutes?nomdufichier=referencement&zipcode=${num_dep}`)
            .then(response => response.json())
            .then(tables => setTables(tables))
    };

    function capitalizeWords(str:string) {
        return str
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }       
    
    useEffect(() => {
        loadDepartmentRoutes(departement.num_dep);

        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=departements`)
          .then(response => response.json())
          .then(autresdepartements => setAutresDepartements(autresdepartements))
      }, []);
    
    return (
        <>
        <div className="cover-index d-flex align-items-center">
            <DevisCover />
        </div>
        <div className="bg-light d-flex align-items-center justify-content-center">
            <p onClick={(e:any) => {Createlink('')}}>Accueil </p>
            <p className="mx-3">&gt;</p>
            <p onClick={(e:any) => {Createlink('location-autocar-'+departement.region.link)}}>
                {departement.region.name}
            </p>
            <p className="mx-3">&gt;</p>
            <p>{departement.name}  ({departement.num_dep})</p>
        </div>
        <div className="text-center">
            <h1 className="bg-dark">Devis de location d’autocar au départ {departement.type}{startsWithVowel ? '' : ' '}{departement.name}</h1>
        </div>
        <div className="text-center">
            Service de location d’autocar avec chauffeur au départ {departement.type}{startsWithVowel ? '' : ' '}{departement.name}.<br/>
            Sur le département {departement.type}{startsWithVowel ? '' : ' '}{departement.name} nous disposons d’une flotte d’autocars et de bus qui nous permettent de répondre à vos besoins de transports.
        </div>
        <br />
        <h2 className="text-center"><small>Nos dernières demandes au départ {departement.type}{startsWithVowel ? '' : ' '}{departement.name} par ville</small></h2>
        <Table>
            <tbody>
                {tables
                    .sort((a: any, b: any) => a.villego.label.localeCompare(b.villego.label))
                    .map((table: any, index: Key) => (
                    <tr key={index}>
                        <td onClick={(e:any) => {Createlink('location-autocar-'+table.villego.label+'-'+table.villego.zip_code)}}>{capitalizeWords(table.villego.label)}</td>
                        <td onClick={(e:any) => {Createlink('location-autocar-'+table.villeaway.label+'-'+table.villeaway.zip_code)}}>{capitalizeWords(table.villeaway.label)} ({table.villeaway.department_number})</td>
                        <td className="text-end">{table.nbrepax} {table.type}</td>
                        <td className="text-end">{table.vehicule.nbrevehicule} {table.vehicule.vehicule}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <br />
        <div className="bg-light">
            <br />
            <h2 className="text-center"><small>Locations d'autocars au départ des autres départements de la région {departement.region_name}</small></h2>
            <br />
            <Row xs={3} md={6} className="mx-auto d-flex justify-content-center container">
                {autresdepartements
                    .filter((autresdepartement: any) => autresdepartement.region.name === departement.region.name)
                    .filter((autresdepartement: any) => autresdepartement.dep_name !== departement.dep_name)
                    .map((autresdepartement: any, index: Key) => (
                        <Card key={index} className="bg-light border-0 text-center mb-3" onClick={(e:any) => {CreatelinkDepartment('location-autocar-'+autresdepartement.num_dep+'-'+autresdepartement.dep_name, autresdepartement.num_dep)}}>{autresdepartement.name}</Card>
                ))}
            </Row>
            <br />
        </div>
        </>
    );
}

export default Department;
