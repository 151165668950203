import React from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import ReactEcofriendly from '../../assets/advantage/ecofriendly.svg';
import ReactExpert from '../../assets/advantage/expert.svg';
import ReactCoordination from '../../assets/advantage/coordination.svg';
import ReactDevis from '../../assets/advantage/stopwatch.svg';
import ReactGaranties from '../../assets/advantage/insurance.svg';

const Features: React.FC = () => {

    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
    }


  return (
    <>
        <Container fluid>
   
        <br/>
        <br/>
        <Row xs={1} md={2} lg={3} xl={5} className="mx-auto d-flex justify-content-center">

            <Card className="features card border-0">
                <div className="text-center " onClick={(e:any) => {Createlink('eco-responsable')}}>
                    <img src={ReactEcofriendly} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Eco<br />Responsable</h5>
                        <p className="card-text text-left">
                            Étant conscients de l’urgence climatique, nous mettons tout en œuvre pour proposer une offre de transport
                            qui permette d’<strong>alléger l'emprunte carbone</strong> que nous avons sur la planète.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('conseil-specialise-et-adapte')}}>
                    <img src={ReactExpert} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Un conseiller<br />spécialisé</h5>
                        <p className="card-text text-left">
                            Notre conseiller analyse vos besoins et envies en amont afin de vous aider à organiser un 
                            <strong> événement optimal</strong> dans le respect de la réglementation et de votre cahier des charges.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('coordination-sur-place')}}>
                    <img src={ReactCoordination} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Coordination<br />sur place</h5>
                        <p className="card-text text-left">
                            Spécialiste de la logistique transport de l’événement, CapEvent vous <strong>accompagne sur place</strong> lors
                            de vos événements sociaux, commerciaux et culturels. Pour que votre évènement soit notre réussite.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('devis-simple-et-rapide')}}>
                    <img src={ReactDevis} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Devis<br />immédiat</h5>
                        <p className="card-text text-left">
                            En plus de proposer les tarifs les plus avantageux possible, notre service de <strong>devis immédiat </strong>
                            vous permet d’obtenir de manière instantanée une estimation. Un conseiller échangera avec vous par la suite pour optimiser votre projet.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

            <Card className="features card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('garanties-et-securites')}}>
                    <img src={ReactGaranties} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
                    <div className="card-body">
                        <h5 className="card-title">Sécurité<br />et garanties</h5>
                        <p className="card-text text-left">
                            Notre <strong>flotte</strong> de véhicules est <strong>récente</strong> et dispose des dernières technologies en terme de sécurité et nos assurances
                            couvrent l'ensemble de la prestation de transport, parce que la sécurité n'est pas une option.
                            [...]
                        </p>
                    </div>
                </div>
            </Card>

        </Row>
        <br/>

        </Container>
        <br/>
        <br/>
    </>
  );
};

export default Features