import React, { useEffect, useState } from 'react';
import { AppFooter } from './components/appfooter/AppFooter';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, dbCollection } from './utils/firebaseconfig';
import { onValue, ref } from 'firebase/database';
import { Navbar } from 'react-bootstrap';
import MenuItems from './components/appheader/MenuItems';
import UserContext from './utils/usercontext';
import AppRouter from './utils/approuter/Approuter';

const App: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [userProfile, setUserProfile] = useState<any>(null);

  const apiKey = process.env.REACT_APP_GOOGLEAPIKEY;

  if (!apiKey) {
    console.error('La clé API Google Maps n\'est pas définie dans le fichier .env.local.');
  } else {
    (window as any).apiKey = apiKey;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // L'utilisateur est connecté, mettre à jour le contexte avec les informations de l'utilisateur
        const userProfileRef = ref(dbCollection, 'users/' + auth.currentUser?.uid);

        onValue(userProfileRef, (snapshot) => {
          const userProfileData = snapshot.val();
          setUserProfile(userProfileData);
        });
        setUser(user);
      }
    });

    // Nettoyage de l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Numéro de téléphone obfusqué
    const obfuscatedPhoneNumber = '+33 9 81 42 87 71';

    // Décrypte le numéro de téléphone lorsqu'un utilisateur clique sur le lien
    const handlePhoneLinkClick = () => {
      const realPhoneNumber = obfuscatedPhoneNumber.replace(/\D/g, ''); // Supprime tous les caractères non numériques
      window.location.href = `tel:${realPhoneNumber}`;
    };

    // Attachez un gestionnaire d'événements au lien téléphonique
    const phoneLink = document.getElementById('phone-link');
    if (phoneLink) {
      phoneLink.addEventListener('click', handlePhoneLinkClick);
    }

    return () => {
      // Retirez le gestionnaire d'événements lorsque le composant est démonté
      if (phoneLink) {
        phoneLink.removeEventListener('click', handlePhoneLinkClick);
      }
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, userProfile, setUser, setUserProfile }}>
      <header className="App-header mx-auto mt-kease">
        <Navbar className="justify-content-end color-white" fixed="top" expand="xl">
          <div><noindex>
            <a id="phone-link">
              <strong>+33 (0)9 81 42 87 71 </strong>
            </a></noindex>
          </div>
          <div className="mx-3 pr-3">du lundi au vendredi de 8h à 19h</div>
        </Navbar>
        <MenuItems />
      </header>
      <AppRouter />
      <AppFooter />
    </UserContext.Provider>
  );
};

export default App;
