import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebaseconfig';
import '../../index.css';
import {ReactComponent as ReactLogo} from '../../assets/logo/logo.svg';
import {ReactComponent as ReactCompte} from '../../assets/app/appcompte.svg';
import {ReactComponent as ReactInformations} from '../../assets/app/appinformations.svg';
import {ReactComponent as ReactDevis} from '../../assets/app/appdevis.svg';
import {ReactComponent as ReactDemandes} from '../../assets/app/appdemandes.svg';
import {ReactComponent as ReactFactures} from '../../assets/app/appfactures.svg';
import {ReactComponent as ReactContact} from '../../assets/app/appchat.svg';

import {ReactComponent as ReactTarifs} from '../../assets/app/apptarifs.svg';
import {ReactComponent as ReactDroits} from '../../assets/app/appdroits.svg';
import {ReactComponent as ReactGestDevis} from '../../assets/app/appgestdevis.svg';
import {ReactComponent as ReactExploit} from '../../assets/app/appexploit.svg';
import {ReactComponent as ReactPlanning} from '../../assets/app/appplanning.svg';

import {ReactComponent as ReactDisconnect} from '../../assets/app/appdisconnect.svg';
import { useContext, useEffect, useState } from 'react';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { useLinkNavigation } from './MenuFonctions';
import UserContext from '../../utils/usercontext';
import LinkPublicMenu from './LinkPublicMenu';

const PrivateMenu: React.FC = () => {

    const { expanded, expandedMenu, toggleExpanded, toggleExpandedMenu, createLink} = useLinkNavigation();
    const { userProfile, setUser, setUserProfile } = useContext(UserContext)
    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
      };

    const navigate = useNavigate();

    const handleLogout = async () => {
      await auth.signOut();
      setUser(null);
      setUserProfile(null);
      navigate('/');
    };
    
    let direction: DropDirection;
    if (window.innerWidth < 1000) {
        direction = 'up';
    } else {
        direction = 'down';
    };

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    return (
        <>
        {auth.currentUser && (
            <Navbar expanded={expanded} onToggle={toggleExpanded} className="color-white mt-kease" fixed="top" expand="xl">
                <Container fluid>
                        <Navbar.Brand className="app-header-logo"  onClick={() => handleClick("")}>
                            <ReactLogo className="bi bi-cloud-check" width="70" height="70"/>{' '}
                        </Navbar.Brand>
                    <Navbar.Toggle/>
                    
                    {expandedMenu && 
                        <LinkPublicMenu onExpandedChange={toggleExpanded}/>
                    }

                    {!expandedMenu && !isSmallScreen &&
                        <LinkPublicMenu onExpandedChange={toggleExpanded}/>
                    }

                    <Navbar.Collapse className="text-white justify-content-end">
                        <Nav className="justify-content-end">
                            <NavDropdown onClick={() => toggleExpandedMenu() } drop={direction} align="end" title={auth.currentUser.email}>
                                {userProfile?.statut ==='adminchayin' && (
                                    <>
                                        <NavDropdown.Item onClick={() => handleClick("gestion-tarifs")}>
                                            <div className="d-flex align-items-center">
                                                <ReactTarifs className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Gestion des tarifs</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("gestion-droits")}>
                                            <div className="d-flex align-items-center">
                                                <ReactDroits className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Gestion des droits</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider /> 
                                        <NavDropdown.Item onClick={() => handleClick("gestion-devis")}>
                                            <div className="d-flex align-items-center">
                                                <ReactGestDevis className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Gestion des devis</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("exploitation")}>
                                            <div className="d-flex align-items-center">
                                                <ReactExploit className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Suivi de l'exploitation</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("planning")}>
                                            <div className="d-flex align-items-center">
                                                <ReactPlanning className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Planning</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider /> 
                                    </>
                                )}
                                <NavDropdown.Item onClick={() => handleClick("mon-compte")}>
                                    <div className="d-flex align-items-center">
                                        <ReactCompte className="align-middle m-1" width="16" height="16" />
                                        <a className="ml-2 mb-0">Mon compte</a>
                                    </div>
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => handleClick("mes-informations")}>
                                    <div className="d-flex align-items-center">
                                        <ReactInformations className="align-middle m-1" width="16" height="16" />
                                        <a className="ml-2 mb-0">Mes informations</a>
                                    </div>
                                
                                </NavDropdown.Item>
                                {(userProfile?.statut ==='user' || userProfile?.statut ==='adminchayin') && (
                                    <>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => handleClick("demandes-devis")}>
                                            <div className="d-flex align-items-center">
                                                <ReactDevis className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Mes projets</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("dossiers-en-cours")}>
                                            <div className="d-flex align-items-center">
                                                <ReactDemandes className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Mes dossiers en cours</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("factures")}>
                                            <div className="d-flex align-items-center">
                                                <ReactFactures className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Mes factures</a>
                                            </div>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => handleClick("contact-us")}>
                                            <div className="d-flex align-items-center">
                                                <ReactContact className="align-middle m-1" width="16" height="16" />
                                                <a className="ml-2 mb-0">Contactez-nous</a>
                                            </div>
                                        </NavDropdown.Item>
                                    </>
                                )}
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    <div className="d-flex align-items-center">
                                        <ReactDisconnect className="align-middle m-1" width="16" height="16" />
                                        <a className="ml-2 mb-0">Se déconnecter</a>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )}
        </>
    );

};

export default PrivateMenu


