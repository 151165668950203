import { Key, useEffect, useState } from "react";
import DevisCover from "../onepage/DevisCover";
import { Card , Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const Arrondissement: React.FC<{ city: any}> = ({ city }) => {
    const navigate = useNavigate();
    
    function CreatelinkCity(linky:any, insee_code: string){
        navigate('/' + linky, { replace: true });
        loadCityRoutes(insee_code);
        window.scrollTo(0, 0);
    }

    function Createlink(linky:any){
        navigate('/' + linky, { replace: true });
    }
    
    function capitalizeWords(str:string) {
        return str
          .split(' ')
          .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }

    const [tables, setTables] = useState<any>([]);
    const [autrescitys, setAutresCitys] = useState<any>([]);

    const loadCityRoutes = (insee_code: string) => {
        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createVilleRoutes?nomdufichier=referencement&zipcode=${insee_code}`)
            .then(response => response.json())
            .then(tables => setTables(tables))
    };

    const labelCounts = autrescitys.reduce((acc: {[key: string]: number}, autrescity: any) => {
        acc[autrescity.label] = (acc[autrescity.label] || 0) + 1;
        return acc;
    }, {});

    const filteredAutresCitys = autrescitys
        .filter((autrescity: any) => autrescity.department_number === city.department_number && autrescity.label !== city.label)
        .sort((a: any, b: any) => a.label.localeCompare(b.label));
    
    const arrondissement = city

    const seen = new Set();
    const detail = new Set();

    useEffect(() => {
        loadCityRoutes(city.label);

        fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=cities`)
            .then(response => response.json())
            .then(autrescitys => setAutresCitys(autrescitys));
      }, []);

    return (
        <>
        <div className="cover-index d-flex align-items-center">
            <DevisCover />
        </div>
        <div className="bg-light d-flex align-items-center justify-content-center">
            <p onClick={(e:any) => {Createlink('')}}>Accueil </p>
            <p className="mx-3">&gt;</p>
            <p onClick={(e:any) => {Createlink('location-autocar-'+city.region_link)}}>
                {city.region_name}
            </p>
            <p className="mx-3">&gt;</p>
            <p onClick={(e:any) => {Createlink('location-autocar-'+city.department_number+'-'+city.department_link)}}>
                {city.department_name} ({city.department_number})
            </p>
            <p className="mx-3">&gt;</p>
            <p>{capitalizeWords(city.label)}</p>
        </div>
        <div className="text-center">
            <h1 className="bg-dark">Devis de location d’autocar au départ de la ville {capitalizeWords(city.label)}</h1>
        </div>
        <div className="text-center">
            Service de location d’autocar avec chauffeur au départ de la ville {capitalizeWords(city.label)}.<br/>
            Sur le département {city.type}{city.name} nous disposons d’une flotte d’autocars et de bus qui nous permettent de répondre à vos besoins de transports.
        </div>
        <br />
        <br />
        <h2 className="text-center"><small>Nos dernières demandes au départ de la ville de {capitalizeWords(city.label)}</small></h2>
        <Table>
            <tbody>
                {tables
                    .sort((a: any, b: any) => a.villego.zip_code.slice(-2).localeCompare(b.villego.zip_code.slice(-2)))
                    .map((table: any, index: Key) => {
                        const isDuplicate = seen.has(table.villego.zip_code);
                        seen.add(table.villego.zip_code);
                        return(
                            !isDuplicate && (
                                <tr key={index}>
                                    <td onClick={(e:any) => {CreatelinkCity('location-autocar-'+table.villego.label+'-'+table.villego.zip_code, table.villego.insee_code)}}>{capitalizeWords(table.villego.label)} ({table.villego.zip_code})</td>
                                    <td onClick={(e:any) => {CreatelinkCity('location-autocar-'+table.villeaway.label+'-'+table.villeaway.zip_code, table.villeaway.insee_code)}}>{capitalizeWords(table.villeaway.label)} ({table.villeaway.department_number})</td>
                                    <td className="text-end">{table.nbrepax} {table.type}</td>
                                    <td className="text-end">{table.vehicule.nbrevehicule} {table.vehicule.vehicule}</td>
                                </tr>)
                 )})}
            </tbody>
        </Table>
        <br />
        <div className="bg-light">
            <br />
            <h2 className="text-center"><small>Locations d'autocars au départ des autres arrondissements de {capitalizeWords(city.label)}</small></h2>
            <br />
            <Row xs={3} md={6} className="m-0 mx-auto d-flex justify-content-center container">
                {tables
                    .map((table: any, index: Key) => {
                        const isDuplicate = detail.has(table.villego.zip_code);
                        detail.add(table.villego.zip_code);
                        return(
                            !isDuplicate && (
                                <p key={index} className="bg-light border-0 text-center mb-3"
                                onClick={() => {CreatelinkCity(`location-autocar-${table.villego.label}-${table.villego.zip_code}`, table.villego.insee_code)}}
                                >{table.villego.city_code}
                                </p>)
                 )})}
            </Row>
            <br />
        </div>
        </>
    );
}

export default Arrondissement;
