import React, { useState } from 'react';


const SendMail: React.FC = () => {

    
  
    return (
      <div>

      </div>
    );
  };
  

export default SendMail;