import { get, ref } from "firebase/database";
import { dbCollection } from "./firebaseconfig";

export const geolocalisation = (): Promise<{ latitude: number, longitude: number }> => {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude, longitude });
        },
        () => {
          reject('Absence de réponse - vérifiez que vous avez bien autorisé la géolocalisation');
        }
      );
    } else {
      reject("La géolocalisation n'est pas prise en charge par votre navigateur.");
    }
  });
};

 // Une fonction pour obtenir l'adresse à partir des coordonnées géographiques
 export const getAddressFromCoordinates = async (latitude: any, longitude: any) => {
  try {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLEAPIKEY}`);
    const data = await response.json();
    if (data.status === 'OK' && data.results[0]) {
      return data.results[0].formatted_address;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'adresse :', error);
    return 'Adresse inconnue';
  }
};

export const geocodeAddress = (address: any) => {
  const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLEAPIKEY}`;

  return new Promise((resolve, reject) => {
    fetch(geocodingUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const firstResult = data.results[0];
          const latitude = firstResult.geometry.location.lat;
          const longitude = firstResult.geometry.location.lng;
          resolve({ latitude, longitude }); 
        } else {
          reject('Adresse non trouvée.');
        }
      })
      .catch((error) => {
        reject('Erreur lors de la requête de géocodage : ' + error);
      });
  });
};

export const initAutocomplete = (query : string, setQuery: (query: string) => void) => {

      const inputElement = document.getElementById(query) as HTMLInputElement;
      const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
        types: ['geocode'],
        componentRestrictions: { country: 'FR' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        if(place.formatted_address !== undefined) {
          const address = place.formatted_address;
          setQuery(address);
        }

    });

};

export const getCityFromAddress = (address: string) => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results && results[0]) {
        for (const component of results[0].address_components) {
          if (component.types.includes('locality')) {
            resolve(component.long_name);
            return;
          }
        }
        reject('Ville non trouvée');
      } else {
        reject('Erreur lors de la recherche de l\'adresse');
      }
    });
  });
}

