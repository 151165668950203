import React from 'react';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import DevisCover from './DevisCover';
import ReactBerline from '../../assets/vehicules/berline.svg';
import ReactVan from '../../assets/vehicules/van.svg';
import ReactMinibus from '../../assets/vehicules/minibus.svg';
import ReactAutocar from '../../assets/vehicules/autocar.svg';
import ReactGrandecapa from '../../assets/vehicules/grandecapa.svg';

const Flotte: React.FC = () => {
  return (
    <div className="justify-content-center">
      <h1 className="bg-dark text-center m-0">Notre flotte</h1>
      <br/>
      <p>
        En fonction de vos besoins, nous choisissons le ou les véhicules les plus adaptés.
        Nos autocars, bus, minibus, van et berline sont disponibles en versions tourisme ou luxe.
        Ils sont également équipés tout confort : sièges inclinables, accoudoirs et repose-pieds, climatisation,
        accès au Wi-Fi, prises, micros, vidéo, etc.
      </p>
      <br/>
      <div className="cover-index d-flex align-items-center">
        <DevisCover />
      </div>
      <br />
      <br />

      <Row xs={1} md={2} lg={3} xl={5} className="mx-auto d-flex justify-content-center">

            <Card className="features card border-0">
              <div className="text-center">
                <img src={ReactBerline} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body className="text-center">
                <Card.Title>Berline</Card.Title>
                <br />
                <Card.Text className="text-center">1/3 passagers</Card.Text>
              </Card.Body>
            </Card>

            <Card className="features card border-0">
              <div className="text-center">
                <img src={ReactVan} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body className="text-center">
                <Card.Title>Van</Card.Title>
                <br />
                <Card.Text className="text-center">4/7 passagers</Card.Text>
              </Card.Body>
            </Card>

            <Card className="features card border-0">
              <div className="text-center">
                <img src={ReactMinibus} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body className="text-center">
                <Card.Title>Minibus</Card.Title>
                <br />
                <Card.Text className="text-center">16/19 passagers</Card.Text>
              </Card.Body>
            </Card>

            <Card className="features card border-0">
              <div className="text-center">
                <img src={ReactAutocar} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body className="text-center">
                <Card.Title>Autocar</Card.Title>
                <br />
                <Card.Text className="text-center">49/65 passagers</Card.Text>
              </Card.Body>
            </Card>

            <Card className="features card border-0">
              <div className="text-center">
                <img src={ReactGrandecapa} className="bi bi-cloud-check align-self-center" width="150" height="150"/>
              </div>
              <Card.Body className="text-center">
                <Card.Title>Double Étage</Card.Title>
                <br />
                <Card.Text className="text-center">80/93 passagers</Card.Text>
              </Card.Body>
            </Card>

        </Row>

      <br />
    </div>
  );
};

export default Flotte;
