import { Button, Card, Container, Modal, Table } from "react-bootstrap";
import { dbCollection } from "../../../utils/firebaseconfig";
import { ref, onValue, update, off, get, remove } from "firebase/database";
import { useEffect, useState } from "react";
import { formatDateLong, formatDateShort, toggleField } from "../../../utils/globalfunctions";
import { geocodeAddress, getCityFromAddress } from "../../../utils/devisfunction";
import { useNavigate } from "react-router-dom";


interface DevisData {
  key: string;
  datedevis:string;
  firstname: string;
  lastname: string;
  email: string;
  departure: string;
  arrival: string;
  datedeparture: string;
  datearrival:string;
  phone:string;
  nombrepax: number;
  tarifTTC: number;
  statut: string;
}

const Gestiondevis: React.FC = () => {
  const [devis, setDevis] = useState<DevisData[]>([]);
  const [submittedmail, setSubmittedMail] = useState(false);
  const [mail, setMail] =useState('');

  useEffect(() => {
    const devisRef = ref(dbCollection, "devis");
  
    const fetchdevisData = async (snapshot: any, setdevisData: (data: DevisData[]) => void) => {
      const devisData: DevisData[] = [];
  
      const promises: Promise<void>[] = [];
  
      snapshot.forEach((childSnapshot: any) => {
        const devis = childSnapshot.val();
  
        async function getDepartureCity() {
          try {
            const departurecity = await getCityFromAddress(devis.departureaddress);
            return departurecity;
          } catch (error) {
            return 'Ville non trouvée';
          }
        }
  
        async function getArrivalCity() {
          try {
            const arrivalcity = await getCityFromAddress(devis.arrivaladdress);
            return arrivalcity;
          } catch (error) {
            return 'Ville non trouvée';
          }
        }

        // Ajouter la promesse à la liste des promesses
        promises.push(
          Promise.all([getDepartureCity(), getArrivalCity()]).then(([departurecity, arrivalcity]) => {
            devisData.push({
              key: childSnapshot.key,
              firstname: devis.firstname,
              lastname: devis.lastname,
              email: devis.email,
              phone: devis.phone,
              departure: departurecity as string,
              arrival: arrivalcity as string,
              datedeparture: devis.datego,
              datearrival: devis.dateback,
              datedevis: devis.dateofcreation,
              nombrepax: devis.nbrepax,
              tarifTTC: devis.tarifTTC,
              statut: devis.statut,
            });
          })
        );
      });
  
      // Attendre que toutes les promesses se terminent avant de mettre à jour les données
      await Promise.all(promises);
      setDevis(devisData);
    };
  
    const devisRefOff = onValue(devisRef, (snapshot) => {
      fetchdevisData(snapshot, setDevis);
    });
  
    return () => {
      off(devisRef, "value", devisRefOff);
    };
  }, []);


  const deleteDevis = (devisKey: any) => {
    const devisRef = ref(dbCollection, `devis/${devisKey}`);
  
    // Utilisez la fonction `remove` pour supprimer le devis
    remove(devisRef)
      .then(() => {
        console.log('Le devis a été supprimé avec succès');
        // Vous pouvez mettre à jour l'état de votre application ou effectuer d'autres actions ici
      })
      .catch((error: any) => {
        console.error('Erreur lors de la suppression du devis :', error);
        // Gérez les erreurs ici, par exemple, affichez un message d'erreur à l'utilisateur
      });
  };

  const envoyerDevis = async (devisKey: string) => {
    const devisRef = ref(dbCollection, `devis/${devisKey}`);

    try {
      const snapshot = await get(devisRef);
      const devis = snapshot.val();
      if( devis.devisnumber !== null ) {
        const htmlText = `
          <p>Bonjour,</p>
          <p>Nous avons le plaisir de vous présenter ci-joint votre solution de mobilité établie suivant les informations fournies lors de votre demande sur Autocar-avec-Chauffeur.com.</p>
          <p>La satisfaction de nos clients est au cœur de nos engagements.</p>
          <p>Votre solution Autocar-avec-chauffeur.com vous propose une mobilité sur mesure avec chauffeur partout en France et en Europe.</p>
          <p>En vous souhaitant une bonne réception et excellente journée</p><br/>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="flex: 0 0 auto; width: 10%;">
              <img style="width: 70px; height: 70px;" src="https://www.autocar-avec-chauffeur.com/logo.svg">
            </div>
            <div style="flex: 0 0 auto; width: 25%;">
              <strong>L'équipe d'Autocar avec chauffeur</strong>
              <p>+33 (0)9 81 42 87 71</p>
              <p>contact@autocar-avec-chauffeur.com</p>
            </div>
          </div>
          <p>CAPTRAVEL SAS - 12 rue Traversière - 92100 BOULOGNE-BILLANCOURT</p>
          <p>CPTV : EV 11 19 03311</p>
          <br/>
          <small>Conformément au Règlement Européen sur la Protection des Données (RGPD UE 2016/679), vous avez le droit de demander à consulter / corriger / vous opposer / exporter / supprimer le contenu des informations personnelles détenues dans notre système d'information.</small>
          <small>Pour toute réclamation concernant l'exercice de vos droits vous pouvez nous adresser un courriel à l'adresse dpo@autocar-avec-chauffeur.com, nous appeler au : 09 81 42 87 71 ou vous adresser à la CNIL.</small>
        `;
        const response = await fetch(process.env.REACT_APP_SERVEURADRESS + '/email/send', {
          method: 'POST', // Utilisez la méthode POST pour envoyer des données au serveur
          headers: {
            'Content-Type': 'application/json', // Définissez le type de contenu de la requête
          },
          body: JSON.stringify({
            to: devis.email,
            subject: 'Autocar avec chauffeur',
            text: htmlText,
          }),
        });
    
        if (response.ok) {
          setMail(devis.email);
          toggleField(setSubmittedMail);
        } else {
          console.error('Error sending e-mail:', response.statusText);
        }
      }
    } catch (error) {
      console.error('Error sending e-mail:', error);
    }
  };

  // Triez les devis par date de création la plus récente
  const sortedDevis = [...devis].sort((a, b) => {
    // Convertissez les dates ISO 8601 en objets Date
    const dateA = new Date(a.datedevis);
    const dateB = new Date(b.datedevis);
    // Triez en ordre décroissant (du plus récent au plus ancien)
    return dateB.getTime() - dateA.getTime();
  });

  async function updateStatus(key: string, newStatus: string, email: string) {
    // Vérifiez si l'email se termine par "@capevent.fr"
    if (email.endsWith('@capevent.fr')) {
      await update(ref(dbCollection, `devis/${key}`), {
        statut: newStatus,
      });
    }
  }
  
  const navigate = useNavigate();
    
  function Createlink(linky:any){
    navigate('/gestion-devis/' + linky, { replace: true });
  }

  const Test  = async (uid: string) => {

    await fetch(`${process.env.REACT_APP_SERVEURADRESS}/pdf/generatePdf`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          datatype: 'devis',  // Exemple de datatype
          uid: uid,    // Exemple de number
      })
    })
    .then(response => response.json())
    .catch((error) => {
        console.error('Error:', error);
    });
    setDriversNeeded('Document crée');
  }

  const [driversNeeded, setDriversNeeded] = useState('');

  return (
    <>
    <h1 className="bg-dark text-center m-0">Gestion des devis</h1>

      <Table>
        <thead>
          <tr>
            <th>Date de la demande</th>
            <th>Client</th>
            <th className="text-end">Date Départ</th>
            <th className="text-end">Date Retour</th>
            <th>Prestation</th>
            <th className="text-end">Nombre de pax</th>
            <th className="text-end">Tarif</th>
            <th className="text-end">Contact</th>
            <th className="text-end">Statut</th>
            <th className="text-end">Accès au devis</th>
            <th className="text-end">Envoyer le devis</th>
            <th className="text-end">Supprimer</th>
          </tr>
        </thead>
        <tbody>
          {sortedDevis.map((devis) => (
            <tr key={devis.key} >
              <td >{formatDateShort(devis.datedevis)}</td>
              <td>{devis.firstname} {devis.lastname}</td>
              <td className="text-end">{formatDateShort(devis.datedeparture)}</td>
              <td className="text-end">{formatDateShort(devis.datearrival)}</td>
              <td>{devis.departure} - {devis.arrival}</td>
              <td className="text-end">{devis.nombrepax}</td>
              <td className="text-end">{devis.tarifTTC}</td>
              <td className="text-end">
                <a href={`mailto:${devis.email}`} className="text-kease">
                  {devis.email}
                </a><br/>
                <a href={`tel:${devis.phone}`} className="text-kease">
                  {devis.phone}
                </a>
              </td>
              <td className="text-end">{devis.statut}</td>
              <td className="text-end">
                <Button variant="kease" size="sm" onClick={() => Createlink(devis.key)}>
                  Visualiser
                </Button>
              </td>
              <td className="text-end">
                <Button variant="kease" size="sm" onClick={() => envoyerDevis(devis.key)}>
                  Envoyer
                </Button>
              </td>
              <td className="text-end">
                <Button variant="danger" size="sm" onClick={() => deleteDevis(devis.key)}>
                  Supprimer
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
          
      <Modal show={submittedmail} onHide={() => toggleField(setSubmittedMail)}>
        <Modal.Header closeButton className="bg-kease">
          <Modal.Title>Le mail a été envoyé</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Le mail a bien été envoyé à {mail}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Gestiondevis;
