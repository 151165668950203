import { Button, Card, Container, Table } from "react-bootstrap";
import { dbCollection } from "../../../utils/firebaseconfig";
import { ref, onValue, update, off, get } from "firebase/database";
import { useEffect, useState } from "react";
import { formatDateLong } from "../../../utils/globalfunctions";

interface UserData {
  key: string;
  firstname: string;
  lastname: string;
  email: string;
  userRole: string;
  phone: string;
  dateofcreation:string;
  nombredevis:number;
  datedernierdevis:any;
}

interface NonUserData {
  key: string;
  firstname: string;
  lastname: string;
  email: string;
  userRole: string;
  phone: string;
  nombredevis:number;
  datedernierdevis:any;
}

interface CapEventData {
  key: string;
  firstname: string;
  lastname: string;
  email: string;
  userRole: string;
  phone: string;
  dateofcreation:string;
}

const Gestiondroits: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [employees, setEmployees] = useState<CapEventData[]>([]);
  const [admins, setAdmins] = useState<CapEventData[]>([]);
  const [nonusers, setNonUsers] = useState<NonUserData[]>([]);

  useEffect(() => {
    const devisRef = ref(dbCollection, "devis");
    const usersRef = ref(dbCollection, "users");

    const fetchNonUser = async () => {
      const usersSnapshot = await get(usersRef);
      const nonusersData: any = [];
      usersSnapshot.forEach((userChildSnapshot) => {
        const user = userChildSnapshot.val();
        nonusersData.push({
          email: user.email,
        });
      });
      return nonusersData;
    };

    const findUnregisteredUsers = async (devisSnapshot: any, setNonUsersData: (data: UserData[]) => void) => {
      const nonusersData = await fetchNonUser();
      const unregisteredUsers: NonUserData[] = [];

      devisSnapshot.forEach((devisChildSnapshot: any) => {
        const devis = devisChildSnapshot.val();
        // Vérifiez si l'adresse e-mail associée au devis n'existe pas dans la collection d'utilisateurs
        const isunRegistered = nonusersData.some((user: any) => user.email === devis.email);
        const existingUserIndex: number = unregisteredUsers.findIndex((nonuser) => nonuser.email === devis.email);
        if (!isunRegistered) {
          if (existingUserIndex === -1) {
            unregisteredUsers.push({
              key: devis.email,
              firstname: devis.firstname,
              lastname: devis.lastname,
              email: devis.email,
              userRole: 'Non inscrit',
              phone: devis.phone,
              nombredevis: 1,
              datedernierdevis: devis.dateofcreation,
            })
          } else {
            // L'utilisateur existe déjà, mettez à jour les informations
            if (devis.dateofcreation > unregisteredUsers[existingUserIndex].datedernierdevis) {
              unregisteredUsers[existingUserIndex].datedernierdevis = devis.DateDemande;
            }
            if (existingUserIndex !== undefined) {
              // L'utilisateur existe dans le tableau
              if (unregisteredUsers[existingUserIndex] !== undefined) {
                // L'utilisateur existe à cet index
                if (unregisteredUsers[existingUserIndex].nombredevis !== undefined) {
                  // Faites quelque chose avec unregisteredUsers[existingUserIndex].nombredevis
                  unregisteredUsers[existingUserIndex].nombredevis++;
                }
              }
            }
          }
        }
      });
      setNonUsers(unregisteredUsers);
    };

    const fetchDevisInfo = async (userEmail: string) => {
      const devisSnapshot = await get(devisRef);
      let nombredevis = 0;
      let today = new Date(2015, 12, 31);
      let datedernierdevis = today.toISOString();

      devisSnapshot.forEach((devisChildSnapshot: any) => {
        const devis = devisChildSnapshot.val();
        if (devis.Email === userEmail) {
          nombredevis++;
          if (devis.DateDemande > datedernierdevis) {
            datedernierdevis = devis.DateDemande;
          }
        }
      });
      if(nombredevis === 0){
        datedernierdevis =''
      }
      return { nombredevis, datedernierdevis };
    };

    const fetchData = async (snapshot: any, setUsersData: (data: UserData[]) => void) => {
      const usersData: UserData[] = [];
      const employeesData: CapEventData[] = [];
      const adminsData: CapEventData[] = [];
      
      const promises: Promise<void>[] = []; // Initialise un tableau vide de promesses
    
      snapshot.forEach((childSnapshot: any) => {
        const user = childSnapshot.val();
        const userEmail = user.email;
        if (user.statut === 'adminchayin') {
          adminsData.push({
            key: childSnapshot.key,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            userRole: user.statut,
            phone: user.phone,
            dateofcreation: user.dateofcreation,
          });
        } else if (user.email.endsWith('@capevent.fr')) {
          employeesData.push({
            key: childSnapshot.key,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            userRole: user.statut,
            phone: user.phone,
            dateofcreation: user.dateofcreation,
          });
        } else if (user.statut === 'user') {
          // Ajoutez une promesse pour récupérer les informations des devis pour cet utilisateur
          const promise = fetchDevisInfo(userEmail).then(({ nombredevis, datedernierdevis }) => {
            usersData.push({
              key: childSnapshot.key,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              userRole: user.statut,
              phone: user.phone,
              dateofcreation: user.dateofcreation,
              nombredevis: nombredevis,
              datedernierdevis: datedernierdevis,
            });
          });
          promises.push(promise); // Ajoutez la promesse au tableau de promesses
        }
      });
    
      // Attendre que toutes les promesses se terminent avant de mettre à jour les données
      await Promise.all(promises);
      setAdmins(adminsData);
      setEmployees(employeesData);
      setUsers(usersData);
    };

    const usersRefOff = onValue(usersRef, (snapshot) => {
      fetchData(snapshot, setAdmins);
      fetchData(snapshot, setEmployees);
      fetchData(snapshot, setUsers);
    });
    const nonusersRefOff = onValue(devisRef, (snapshot) => {
      findUnregisteredUsers(snapshot, setNonUsers);
    });
    return () => {
      off(usersRef, "value", usersRefOff);
      off(devisRef, "value", nonusersRefOff);
    };

    
  }, []);
  

  // Triez les utilisateurs par date de création la plus récente
  const sortedUsers = [...users].sort((a, b) => {
    // Convertissez les dates ISO 8601 en objets Date
    const dateA = new Date(a.dateofcreation);
    const dateB = new Date(b.dateofcreation);
    // Triez en ordre décroissant (du plus récent au plus ancien)
    return dateB.getTime() - dateA.getTime();
  });

  // Triez les utilisateurs par date de création la plus récente
  const sortedEmployees = [...employees].sort((a, b) => {
    // Convertissez les dates ISO 8601 en objets Date
    const dateA = new Date(a.dateofcreation);
    const dateB = new Date(b.dateofcreation);
    // Triez en ordre décroissant (du plus récent au plus ancien)
    return dateB.getTime() - dateA.getTime();
  });

  // Triez les utilisateurs par date de création la plus récente
  const sortedNonUser = [...nonusers].sort((a, b) => {
    // Convertissez les dates ISO 8601 en objets Date
    const dateA = new Date(a.datedernierdevis);
    const dateB = new Date(b.datedernierdevis);
    // Triez en ordre décroissant (du plus récent au plus ancien)
    return dateB.getTime() - dateA.getTime();
  });

  async function updateUserStatus(keyUser: string, newStatus: string, email: string) {
    // Vérifiez si l'email se termine par "@capevent.fr"
    if (email.endsWith('@capevent.fr')) {
      await update(ref(dbCollection, `users/${keyUser}`), {
        statut: newStatus,
      });
    }
  }

  return (
    <>
    <h1 className="bg-dark text-center m-0">Gestion des droits</h1>
    <Container>

      <Card className="border-0">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Liste des administrateurs</h2>
        </Card.Body>
      </Card>
      <Table >
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th className="text-end">Téléphone</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin.key}>
              <td>{admin.firstname} {admin.lastname}</td>
              <td>{admin.email}</td>
              <td className="text-end">
                <a href={`tel:${admin.phone}`} className="text-kease">
                  {admin.phone}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Card className="border-0">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Liste des employés</h2>
        </Card.Body>
      </Card>
      <Table >
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th className="text-end">Téléphone</th>
            <th className="text-end">Date de création</th>
            <th className="text-end">Activation du compte</th>
          </tr>
        </thead>
        <tbody>
          {sortedEmployees.map((employee) => (
            <tr key={employee.key}>
              <td>{employee.firstname} {employee.lastname}</td>
              <td>{employee.email}</td>
              <td>
                <a href={`tel:${employee.phone}`} className="text-kease">
                  {employee.phone}
                </a>
              </td>
              <td className="text-end"> {formatDateLong(employee.dateofcreation)}</td>
              <td className="text-end">
                {employee.userRole === 'user' ? (
                  <Button size="sm" onClick={() => updateUserStatus(employee.key, 'employee', employee.email)}>
                    Activer le compte
                  </Button>
                ) : (
                  <Button variant="danger" size="sm" onClick={() => updateUserStatus(employee.key, 'user', employee.email)} >
                    Désactiver le compte
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Card className="border-0">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">
            Liste des utilisateurs inscrits
          </h2>
        </Card.Body>
      </Card>
      <Table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th className="text-end">Téléphone</th>
            <th className="text-end">Date de création</th>
            <th className="text-end">Nombre de devis</th>
            <th className="text-end">Date du dernier devis</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => (
            <tr key={user.key}>
              <td>{user.firstname} {user.lastname}</td>
              <td>
                <a href={`mailto:${user.email}`} className="text-kease">
                  {user.email}
                </a>
              </td>
              <td className="text-end">
                <a href={`tel:${user.phone}`} className="text-kease">
                  {user.phone}
                </a>
              </td>
              <td className="text-end"> {formatDateLong(user.dateofcreation)}</td>
              <td className="text-end"> {user.nombredevis}</td>
              <td className="text-end"> {formatDateLong(user.datedernierdevis)}</td>
            </tr>
          ))}
        </tbody>
      </Table>


      <Card className="border-0">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">
            Liste des utilisateurs non inscrits
          </h2>
        </Card.Body>
      </Card>
      <Table>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th className="text-end">Téléphone</th>
            <th className="text-end">Nombre de devis</th>
            <th className="text-end">Date du dernier devis</th>
          </tr>
        </thead>
        <tbody>
          {sortedNonUser.map((user) => (
            <tr key={user.key}>
              <td>{user.firstname} {user.lastname}</td>
              <td>
                <a href={`mailto:${user.email}`} className="text-kease">
                  {user.email}
                </a>
              </td>
              <td className="text-end">
                <a href={`tel:${user.phone}`} className="text-kease">
                  {user.phone}
                </a>
              </td>
              <td className="text-end"> {user.nombredevis}</td>
              <td className="text-end"> {formatDateLong(user.datedernierdevis)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

    </Container>
    </>
  );
};

export default Gestiondroits;
