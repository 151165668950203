import React, { useState } from "react";
import { Form, Button, Card, Alert, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FormInput from "../../utils/website/FormInput";

const CreateAccount: React.FC = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        firstname: '',
        lastname: '',
        phone: ''
    });
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            return setError("Les mots de passe ne sont pas identiques");
        }

        try {
            setError("");
            const response = await fetch(`${process.env.REACT_APP_SERVEURADRESS}/auth/createuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: formData
                  })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur réseau');
                }
                navigate('/login');
                setError("Compte créé");
              });
        } catch {
            setError("Impossible de créer votre compte - veuillez réessayer ultérieurement ou contacter un administrateur");
        }
    };

    return (
        <>
            <Card className="border-0 mx-auto">
                <Card.Body className="mx-auto">
                    <h2 className="text-center mb-3 text-kease">Créer un compte</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form className="mx-auto" onSubmit={handleSubmit}>
                        <FormInput id="email" type="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                        <Form.Group className="row">
                            <Col xs={6}>
                                <FormInput id="password" type="password" placeholder="Mot de passe" value={formData.password} onChange={handleChange} />
                            </Col>
                            <Col xs={6}>
                                <FormInput id="confirmPassword" type="password" placeholder="Confirmer mot de passe" value={formData.confirmPassword} onChange={handleChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group className="row">
                            <Col xs={6}>
                                <FormInput id="firstname" type="text" placeholder="Prénom" value={formData.firstname} onChange={handleChange} />
                            </Col>
                            <Col xs={6}>
                                <FormInput id="lastname" type="text" placeholder="Nom" value={formData.lastname} onChange={handleChange} />
                                </Col>
                        </Form.Group>
                        <FormInput id="phone" type="tel" placeholder="Téléphone" value={formData.phone} onChange={handleChange} />

                        <Button className="btn-kease text-center w-100" type="submit">
                            Créer son compte
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
            <div className="mx-auto text-center mb-2">
                Vous avez déjà un compte? <Link to="/login">Se connecter</Link>
            </div>
            <br/>
        </>
    );
};

export default CreateAccount;
