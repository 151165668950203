import React from "react";
import { Form, Col } from "react-bootstrap";

interface FormInputProps {
    id: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({ id, type, placeholder, value, onChange }) => (
    <Form.Group className="mb-3 row mx-auto" id={id}>
        <Col>
            <Form.Control className="mx-auto" type={type} id={id} value={value} placeholder={placeholder} onChange={onChange} required />
        </Col>
    </Form.Group>
);

export default FormInput;
