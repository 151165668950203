import { Navigate, Route, Routes } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../usercontext';
import Onepage from '../../components/onepage/OnePage';

import CreateAccount from '../../components/auth/CreateAccount';
import Login from '../../components/auth/Login';
import Userwaitvalidation from '../../components/auth/UserValidation';
import Profil from '../../components/auth/UserProfile';
import RequiredAuth from './RequiredAuth';

import Flotte from '../../components/onepage/Flotte';
import Testimonials from '../../components/onepage/Testimonials';
import Reglementation from '../../components/onepage/Reglementation';
import AboutUs from '../../components/entreprise/Aboutus';
import Securite from '../../components/entreprise/Securite';
import MentionsLegales from '../../components/entreprise/Mentionslegales';
import ConditionsGenerales from '../../components/entreprise/Conditionsgenerales';
import AdminAuth from './AdminAuth';
import Gestiondroits from '../../components/auth/admin/Gestiondroits';
import Gestiondevis from '../../components/auth/admin/Gestiondevis';
import Gestiontarif from '../../components/auth/admin/Gestiontarif';
import SendMail from '../../components/auth/admin/sendEmail';
import Devis from '../../components/template/Devis';
import Department from '../../components/referencement/Department';
import Region from '../../components/referencement/Region';
import City from '../../components/referencement/City';
import Ville from '../../components/referencement/Ville';
import Arrondissement from '../../components/referencement/Arrondissement';
import Services from '../../components/onepage/Services';


const AppRouter : React.FC = ()=> {
 
  // Vérifiez si l'utilisateur est déjà connecté lors du chargement de l'application
  const { user, userProfile } = useContext(UserContext);
  const [departments, setDepartments] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [citys, setCitys] = useState<any>([]);
  const [villes, setVilles] = useState<any>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=departements`)
      .then(response => response.json())
      .then(departements => setDepartments(departements))
    fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=regions`)
      .then(response => response.json())
      .then(regions => setRegions(regions))
    fetch(`${process.env.REACT_APP_SERVEURADRESS}/plan/createRoutes?nomdufichier=cities`)
      .then(response => response.json())
      .then(citys => setCitys(citys))
  }, []);

  return (
    <>
        <br/>
        <br/>
        <br/>
        <br/>
      <Routes>
          <Route path={'/'} element={<Onepage />}/>
          <Route path={'/create-account'} element={<CreateAccount />}/>
          <Route path={'/login'} element={<Login />}/>
          <Route path={'/nos-vehicules-avec-chauffeur'} element={<Flotte />}/>
          <Route path={'/references-clients'} element={<Testimonials />}/>
          <Route path={'/reglementation'} element={<Reglementation />}/>
          <Route path={'/services'} element={<Services />}/>

          <Route path={'/entreprise/a-propos'} element={<AboutUs />}/>
          <Route path={'/entreprise/securite'} element={<Securite />}/>
          <Route path={'/entreprise/mentions-legales'} element={<MentionsLegales />}/>
          <Route path={'/entreprise/conditions-generales-de-ventes'} element={<ConditionsGenerales />}/>
          
          {citys.map((city: any) => (
              ['paris', 'marseille', 'lyon'].includes(city.label) ?
                  <Route key={city.insee_code} path={`/location-autocar-${city.label}`} element={<Arrondissement city={city} />} /> :
                  <Route key={city.insee_code} path={`/location-autocar-${city.label}`} element={<Ville city={city} />} />
          ))}

          {citys.map((city: any) => (
            <Route key={city.insee_code} path={`/location-autocar-${city.label}-${city.zip_code}`} element={<City city={city} />} />
          ))} 

          {departments.map((department: any) => (
            <Route key={department.num_dep} path={`/location-autocar-${department.num_dep}-${department.dep_name}`} element={<Department departement={department} />} />
          ))}

          {regions.map((region: any) => (
            <Route key={region.num_region} path={`/location-autocar-${region.region_name}`} element={<Region region={region} />} />
          ))}

          

        {user ? (
            <>
            <Route path={'/wait-validation'} element={<RequiredAuth><Userwaitvalidation /></RequiredAuth>}/>
            <Route path={'/mon-compte'} element={<RequiredAuth><Profil /></RequiredAuth>}/>

            <Route path={'/gestion-droits'} element={<AdminAuth userProfile={userProfile}><Gestiondroits/></AdminAuth>}/>
            <Route path={'/gestion-devis'} element={<AdminAuth userProfile={userProfile}><Gestiondevis/></AdminAuth>}/>
            <Route path={'/gestion-devis/:uid'} element={<AdminAuth userProfile={userProfile}><Devis/></AdminAuth>}/>
            <Route path={'/gestion-tarifs'} element={<AdminAuth userProfile={userProfile}><Gestiontarif/></AdminAuth>}/>
            <Route path={'/envoi-mail'} element={<AdminAuth userProfile={userProfile}><SendMail/></AdminAuth>}/>
            </>
        ) : (
            // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
            <>
            <Route path={'/wait-validation'} element={<Navigate to="/login" />} />
            <Route path={'/user-profile'} element={<Navigate to="/login" />}/>
            </>
        )}
        
          <Route path="*" element={<Onepage />}/>
    
      </Routes>
  </>
  );
};

export default AppRouter