import { useNavigate } from 'react-router-dom';
import '../../index.css';
import { useState } from 'react';

interface LinkNavigationState {
    expanded: boolean;
    expandedMenu: boolean;
    toggleExpanded: () => void;
    toggleExpandedMenu: () => void;
    createLink: (link:any) => void;
};

const initialLinkNavigationState: LinkNavigationState = {
    expanded: false,
    expandedMenu: true,
    toggleExpanded: () => {},
    toggleExpandedMenu: () => {},
    createLink: () => {},
};

export function useLinkNavigation(): LinkNavigationState {
    const navigate = useNavigate();
    const [state, setState] = useState<LinkNavigationState>(initialLinkNavigationState);

    function toggleExpanded() {
        setState(prevState => ({
          ...prevState,
          expanded: !prevState.expanded,
          expandedMenu: true,
        }));
    }

    function createLink(linky:any) {
        window.scrollTo(0, 0);
        setState(prevState => ({
            ...prevState,
            expanded: false,
            expandedMenu: true,
        }));
        navigate('/' + linky, { replace: true });
    }

    function toggleExpandedMenu() {
        setState(prevState => ({
          ...prevState,
          expandedMenu: !prevState.expandedMenu,
        }));
    }
    
    return { ...state, toggleExpanded, toggleExpandedMenu, createLink };
}


