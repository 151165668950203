import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button, Container } from 'react-bootstrap';
import { dbCollection } from "../../utils/firebaseconfig";
import { ref, get, set } from "firebase/database";
import { formatDateDevisArrivee, formatDateDevisDepart, formatDateLong } from '../../utils/globalfunctions';
import { useNavigate, useParams } from 'react-router-dom';
import {ReactComponent as ReactLogo} from '../../assets/logo/logo.svg';
import ChargingTime from '../../utils/chargingtime';

const DevisAR: React.FC<{ devis: any; setDevis: React.Dispatch<any> }> = ({ devis, setDevis }) => {
    const { uid } = useParams();
    const [chargingtime, setChargingTime] = useState(false); 
    const navigate = useNavigate();
    
    function Createlink(){
      navigate('/gestion-devis/', { replace: true });
    }

    async function loadDevis(requestData: { uid: any; database: any; }) {
      try {
          const response = await fetch(`${process.env.REACT_APP_SERVEURADRESS}/firebase/getDataByUidFirebase?uid=${requestData.uid}&database=${requestData.database}`);

          if (!response.ok) {
              throw new Error('La requête a échoué');
          }
          const fetchedDevis = await response.json(); // Attendre la résolution de la promesse
          if (!fetchedDevis) {
              // Redirigez ou affichez un message d'erreur si le devis n'est pas trouvé
              navigate('/gestion-devis', { replace: true });
          } else {
              setDevis(fetchedDevis);
              const today = new Date();
              await setDevis({...fetchedDevis, datedevis: today.toISOString()})
          }
      } catch (error) {
        console.error('Error loading data:', error);
      }
  }

    const createDevisnumber= async () => {
      if (!devis.devisnumber) {
        setChargingTime(true);
        const today = new Date();
        const annee = today.getFullYear();
        const mois =  (today.getMonth() + 1).toString().padStart(2, '0');
        const dateEnNombre = parseInt(annee + mois);
        const devisNumberRef = ref(dbCollection, 'datas/');
        try {
          const snapshot = await get(devisNumberRef);
          const Number = snapshot.val();
          const compare = parseInt(Number.devisnumber.toString().slice(0, 6));
          if( dateEnNombre > compare) {
            const devisRef = ref(dbCollection, `devis/${uid}`);
            await set(devisRef, {
              ...devis,
              devisnumber: parseInt(dateEnNombre.toString() + '0001'),
            });
            await set(devisNumberRef, {devisnumber: parseInt(dateEnNombre.toString() + '0001')});
          } else {
            const devisRef = ref(dbCollection, `devis/${uid}`);
            await set(devisRef, {
              ...devis,
              devisnumber: (Number.devisnumber + 1),
            });
            await set(devisNumberRef, {devisnumber: Number.devisnumber + 1});
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de devisnumber :", error);
        }
        const requestData = {
          uid: uid,
          database: 'devis',
          };
        loadDevis(requestData);
      }
    }

  return (
    <>

    {chargingtime && <ChargingTime chargingtime={chargingtime} setChargingTime={setChargingTime} />}

    <Container>
    <Row className="bondecommande bg-logo">
      <Col xs={1}>
        <div className="leftside align-top form-row align-right">
          <p className="estimationcap align-baseline">Devis - </p>
          <p className="font-weight-bold estimationevent align-baseline">Autocar </p>
          <p className="font-weight-bold estimationcap align-baseline">avec </p>
          <p className="font-weight-bold estimationcap align-baseline">Chauffeur</p>
        </div>
      </Col>
      <Col xs={10} >
        <Row className="border-bottom border-3 border-kease">
            <Col xs={1} ></Col>
          <Col xs={8} className="text-warning font-weight-light rounded text-end titrebondecommande">
            Location d’autocars, bus, minibus, vans et berlines avec chauffeur<br /><br />
          </Col>
          <Col xs={3} className="text-end">
            <ReactLogo className="bi bi-cloud-check" width="150" height="150"/>
          </Col>
        </Row>
        <div className="h4 titrebondecommande text-center">Devis n° {devis?.devisnumber}</div>
        <div className="text-center">(valable sous réserve de confirmation de la part d'Autocar-avec-chauffeur)</div>
        <br />
        <Row className="border-bottom border-3 border-kease">
          <Col xs={6} className="">
            <p>le {formatDateLong(devis?.datedevis)}</p>
          </Col>
          <Col xs={6} className="">
            <p className="text-kease"><strong>A l’attention de :</strong></p>
            <p className="">{devis?.firstname} {devis?.lastname}</p>
            <p className="">{devis?.billing}</p>
            <br/>
          </Col>
        </Row>
        <br/>
        
        <div>
          <strong>Nombre de passagers : {devis?.nbrepax}</strong>
          <br />
          <Table hover className="text-black">
            <tbody>
              <tr>
                <td className="align-top">Départ Aller</td>
                <td className="align-top">{formatDateDevisDepart(devis?.dategodeparture)}</td>
                <td className="align-top">{devis?.departureaddress}</td>
              </tr>
              <tr>
                <td className="align-top">Arrivée Aller</td>
                <td className="align-top">{formatDateDevisArrivee(devis?.dategoarrival)}</td>
                <td className="align-top">{devis?.arrivaladdress}</td>
              </tr>
              <br/>
              <tr>
                <td className="align-top">Départ Retour</td>
                <td className="align-top">{formatDateDevisDepart(devis?.datebackdeparture)}</td>
                <td className="align-top">{devis?.arrivaladdress}</td>
              </tr>
              <tr>
                <td className="align-top">Arrivée Retour</td>
                <td className="align-top">{formatDateDevisArrivee(devis?.datebackarrival)}</td>
                <td className="align-top">{devis?.departureaddress}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <div className="font-weight-bold"><strong>Nombre de véhicules :</strong> {devis?.nbrevehicule} {devis?.vehicule}</div>
          <div className="font-weight-bold"><strong>Nombre de chauffeur :</strong> {devis?.chauffeurs}</div>
        </div>
        <br />
        <div>
          <div className="soustitrebondecommande"><strong>Cette estimation comprend :</strong> péages autoroutiers, frais chauffeur, assurances</div>
          <div className="soustitrebondecommande"><strong>Reste à votre charge :</strong> parkings éventuels</div>
        </div>
        <br />
        <div className="bg-warning font-weight-bold rounded text-center ">
          <div className="h4 titrebondecommande">TARIF TTC : {devis?.tarifTTC} €</div>
          <small className="font-weight-light soustitrebondecommande">Les prestations de transports réalisées en France sont soumises au taux de TVA de 10%</small>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="font-weight-light text-center"><small>CAP TRAVEL - 12 rue Traversière - 92100 Boulogne-Billancourt</small></div>
        <div className="font-weight-light text-center"><small>© Autocar-avec-chauffeur - 882 372 204 RCS de Nanterre - Assureur THELEM ASSURANCE</small></div>
        <br/><br/><br/><br/>
      <br/><br/>
      </Col>
      <br/>
      <Row className="text-center justify-content-between">
        <Col>
        <Button variant="secondary" onClick={() => Createlink()}>
            Fermer
        </Button>
        </Col>
        <Col>
        <Button variant="kease" onClick={createDevisnumber}>
            Créer le devis
        </Button>
        </Col>
    </Row>
    </Row>
      
    
    

    </Container>
    
    <br/><br/><br/><br/>
    </>
  );
}

export default DevisAR;
