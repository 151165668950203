import React from 'react';
import {ReactComponent as ReactLogo} from '../assets/logo/logo.svg';
import { Modal } from 'react-bootstrap';

interface ChargingTimeProps {
  chargingtime: boolean;
  setChargingTime: (value: boolean) => void;
}

const ChargingTime: React.FC <ChargingTimeProps> = ({ chargingtime, setChargingTime }) => {

  return (
    <>
    
    <Modal size="sm" className="text-center modal-transparent" show={chargingtime} onHide={() => setChargingTime(false)}>
      <Modal.Header className="bg-kease text-center">
          <strong className="text-center">Chargement...</strong>
        </Modal.Header>
      <Modal.Body className="text-center modal-transparent">
        <ReactLogo className="bi bi-cloud-check" width="150px" height="150px"/>
        <svg className="spinner" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
      </Modal.Body>
      <Modal.Footer className="bg-kease text-center">
        <br/>
      </Modal.Footer>
    </Modal>

    </>
  );
};

export default ChargingTime;