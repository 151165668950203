import { Container } from 'react-bootstrap';
import Features from './Features';
import DevisCover from './DevisCover';

const Onepage: React.FC = () => {
  
  return (
    <>

      <h1 className="bg-dark text-center m-0">Autocar avec chauffeur | Location d'autocar avec chauffeur</h1>
      <div className="cover-index d-flex align-items-center">
        <DevisCover/>
      </div>
      <Container fluid>
        <Features/>
      </Container>
    </>
  );
};

export default Onepage