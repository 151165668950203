import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Nav } from 'react-bootstrap';

const ConditionsGenerales: React.FC = () => {

    const [isMenuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }, []);
  
    const handleMenuLinkClick = (event : any) => {
      event.preventDefault(); // Empêche le comportement par défaut du lien
    
      const targetSectionId = event.target.getAttribute('href'); // Obtient l'ID de la section ciblée
      const targetSection = document.querySelector(targetSectionId); // Sélectionne la section ciblée
      if (targetSection) {
        const offset = targetSection.offsetTop - 100; // Calcule le décalage en soustrayant 100 pixels
        window.scrollTo({ top: offset, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
      }
    };
    
  return (
    <>
    <div className="text-center">
      <h1 className="bg-dark">Conditions générales de ventes</h1>
    </div>
    
    <Row>
        <Col className="col-3 m-3">
            <Nav className="vertical-nav-menu custom-sticky-top border border-1 border-kease rounded-3">
            <ul className="list-unstyled">
            <li>
                <p className="fw-bold m-2 ps-2">Table des matières</p>
            </li>
            <li>
                <Nav.Link href="#article1" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 1 - Champ d'application
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article2" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 2 - Définitions
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article3" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 3 - Prestation de transport
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article4" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 4 - Rétribution du transport
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article5" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 5 - Informations et documents à fournir au transporteur
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article6" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 6 - Caractéristiques de l’autocar
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article7" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 7 - Sécurité à bord de l'autocar
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article8" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 8 - Bagages
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article9" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 9 - Diffusion publique de musique ou projection d’une oeuvre audiovisuelle
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article10" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 10 - Rémunération du transport et des prestations annexes et complémentaires
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article11" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 11 - Modalités de conclusion et de paiement du contrat
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article12" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 12 - Résiliation su contrat de transport
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article13" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 13 - Exécution du contrat de transport
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article14" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 14 - Modification du contrat de transport en cours de réalisation
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article15" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 15 - Evénements fortuits
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article16" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 16 - Dégradations et cautions
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article17" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 17 - Retards
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article18" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 18 - Formalités
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article19" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 19 - Utilisation des données
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article20" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 20 - Réclamations
                </Nav.Link>
            </li>
            <li>
                <Nav.Link href="#article21" className="text-kease nav-link small" onClick={handleMenuLinkClick}>
                    Article 21 - Différents et attribution de juridiction
                </Nav.Link>
            </li>
            </ul>
        </Nav>
        </Col>
        <Col className="about__info mt-3">
        <p><em>Date de dernière mise à jour : 1er septembre 2023</em></p>
        <br/>
        <section id="article1">
            <h5 className="fw-bold">Article 1 - Champ d'application</h5>
            <p>
                Conformément aux articles L.211-7 et L.211-17 du Code du tourisme, les dispositions des articles R.211-3 à
                R.211-11 du Code du tourisme, dont le texte est ci-dessous reproduit, ne sont pas applicables
                pour les opérations de réservation ou de vente des titres de transport n'entrant pas dans le cadre d'un forfait touristique.
                La brochure, le devis, la proposition, le programme de l'organisateur constituent l'information préalable
                visée par l'article R.211-5 du Code du tourisme. Les conditions particulières de voyage citées sur ce site internet
                seront contractuels dès la signature du bulletin d'inscription.
            </p>
            <p>
                Les présentes conditions de transport s’appliquent au transport de passagers pour tout service régulier ou occasionnel,
                effectué au moyen d’un ou plusieurs véhicules terrestres (moto, berline, van, minibus, minicar, autocar).
            </p>
            <p>
                Cap Event se réserve le droit de modifier à tout moment les présentes conditions générales de transport en publiant une nouvelle version sur son site internet (ci après "Site").
                Les conditions générales de transport sont celles en vigueur à la date de validation de la commande.
            </p>
            <p>
                Le fait de cocher la case relative aux présentes conditions générales de transport lors du processus de validation de la commande par le biais du Site ou de signer le bon de commande
                vaut acceptation sans réserve, par le client, de l’intégralité de ces conditions générales de ventes et de transport de Cap Event.
            </p>
            <p>
                Quelle que soit l’entreprise qui réalise le transport, les présentes conditions générales s'appliquent.
            </p>

        </section>
        <section id="article2">
            <h5 className="fw-bold">Article 2 - Définitions</h5>
            <ul>
                <li>Client : toute personne physique majeure ou personne morale qui sollicite un devis et achète une ou plusieurs prestations de voyage.</li>
                <li>Transporteur/vendeur : la société Cap Event, qui s’engage, en vertu du contrat, à acheminer directement ou en sous-traitance, dans les conditions visées à l’article 1, à titre onéreux, un groupe de personnes et leurs bagages, d’un lieu défini à destination d’un autre lieu défini</li>
                <li>Conducteur : la personne qui conduit l’autocar ou qui se trouve à bord de l’autocar dans le cadre du service pour assurer la relève de son collègue</li>
                <li>Membre d’équipage : la personne chargée de seconder le conducteur ou de remplir les fonctions d’hôtesse, de steward ou de guide</li>
                <li>Passagers : les personnes qui prennent place à bord de l’autocar à l’exception du conducteur et du/des membre(s) d'équipage</li>
                <li>Service : le service qui comporte la mise d’un autocar à la disposition exclusive du donneur d'ordre</li>
                <li>Enfant : personne de moins de dix-huit ans</li>
                <li>Prise en charge initiale : le moment où le premier passager commence à monter dans l’autocar</li>
                <li>Dépose finale : le moment où le dernier passager achève de descendre de l’autocar</li>
                <li>Durée de mise à disposition : le temps qui s’écoule entre le moment où l’autocar est mis à disposition du donneur d’ordre et celui où le transporteur retrouve la liberté d’usage de celui-ci. La durée de mise à disposition inclut le temps de prise en charge et de dépose des passagers et de leurs bagages, variable selon la nature du service, ainsi que les temps d'attente</li>
                <li>Arrêt intermédiaire : lieu autre que le point de prise en charge initiale et le point de dépose finale, où l’autocar doit s’arrêter à la demande exprimée par le donneur d’ordre lors de la conclusion du contrat</li>
                <li>Horaires : les horaires définis en fonction de conditions normales de circulation et de déroulement de transport, garantissant le respect des obligations de sécurité et de la réglementation sociale relative aux temps de conduite et de repos des conducteurs</li>
                <li>Itinéraire : l’itinéraire laissé à l’initiative du transporteur, sauf exigence particulière du donneur d’ordre explicitement indiquée, à charge pour lui d’en informer le transporteur avant le début du service</li>
                <li>Bagages : les biens identifiés transportés à bord de l’autocar ou de sa remorque et appartenant aux passagers</li>
                <li>Bagages placés en soute : les bagages acheminés dans la soute ou dans la remorque de l’autocar</li>
                <li>Bagages à main : les bagages que le passager conserve avec lui</li>
            </ul>
        </section>
        <section id="article3">
            <h5 className="fw-bold">Article 3 - Prestation de transport</h5>
            <p>
                Le droit à la prestation de transport s'applique dès lors qu’un contrat de transport a été valablement conclu.
            </p>
            <p>
                Toute acceptation d'un devis établi par notre agence devra être faite par écrit, en signant le-dit devis et en nous le retournant par email, courrier ou fax ou par le biais de la validation par le site internet.
                Aucune réservation par téléphone ne sera prise en compte.
                La confirmation de réservation (cf. paragraphe 3.1) autorise le passager ou le groupe de passager à effectuer un voyage comme indiqué sur le contrat de transport.
            </p>
        </section> 
        <section id="article4">
            <h5 className="fw-bold">Article 4 - Rétribution du transport</h5>
            <p>
                Le paiement du prix est exigible en échange du transport.
                La totalité du montant peut vous être demandé avant le déplacement.

                Pour être considéré comme définitive, toute inscription à un voyage de plusieurs jours doit être accompagnée
                d'un acompte dont le montant sera défini par le vendeur (Généralement 30% du prix du voyage).
                Le solde du voyage devra nous parvenir au plus tard 30 jours avant votre départ, sans rappel de notre part.
                Si le solde n'est pas versé dans les délais, nous considérerons votre participation comme annulée,
                avec les frais que cette annulation engendrera.
                Pour les réservations à moins de 30 jours avant le départ, la totalité du règlement devra être effectuée le jour de la conclusion du contrat.
            </p>
            <p>
                L'ensemble des Prestations proposées sur le Site n'est pas soumis à l'application du droit de rétractation en matière de vente à distance.
                En conséquence, les trajets et options commandés sur le Site sont exclusivement soumis aux conditions d'annulation et de modification prévues aux présentes Conditions Générales
            </p>

        </section> 
        <section id="article5">
            <h5 className="fw-bold">Article 5 - Informations et documents à fournir au transporteur</h5>
            <p>
                Conformément à l'aticle Article R.211-6, préalablement à la mise du ou des véhicules à la disposition du donneur d'ordre, celui-ci fournit au transporteur par écrit, ou par tout autre procédé en permettant la mémorisation, les indications définies ci-après:
            </p>
            <ul>
                <li>Dates, horaires et itinéraires :</li>
                <ul>
                    <li>la date, l’heure et le lieu de début et de fin de mise à disposition de l’autocar</li>
                    <li>la date, l’heure et le lieu de prise en charge initiale des passagers ainsi que la date, l’heure et le lieu de leur dépose finale</li>
                    <li>la date, l’heure et le lieu des points d’arrêt intermédiaires</li>
                    <li>le cas échéant, l’itinéraire imposé</li>
                </ul>
                <li>Composition du groupe à transporter :</li>
                <ul>
                    <li>le nombre maximum de personnes qui compose le groupe</li>
                    <li>le nombre maximum de personnes à mobilité réduite, dont le nombre de personnes en fauteuil roulant</li>
                    <li>le nombre maximum de personnes de moins de dix-huit ans dans le cadre d’un transport en commun d’enfants et le nombre d’accompagnateurs</li>
                </ul>
                <li>Liste nominative des passagers :</li>
                <ul>
                    <li>Par arrêté ministériel, une liste nominative (nom, prénom) des passagers présents dans le véhicule de transport en commun est obligatoire depuis le 3 Juillet 2009 pour les transports réalisés hors du périmètre constitué par le département de prise en charge du groupe et les départements limitrophes</li>
                    <li>Dans le cadre des transports d’enfants, la liste doit en outre comporter les coordonnées téléphoniques d’une personne à contacter pour chaque enfant transporté</li>
                </ul>
                L’établissement de cette liste est de la responsabilité du client qui devra la remettre au conducteur au moment du départ
                <li>Nature des bagages :</li>
                <ul>
                    <li>le poids et le volume global approximatifs</li>
                    <li>la préciosité et la fragilité éventuelles</li>
                    <li>les autres spécificités éventuelles</li>
                </ul>
                <li>Moyen de communication :</li>
                <ul>
                    <li>les coordonnées téléphoniques permettant au transporteur de joindre le donneur d’ordre à tout moment (vingt-quatre heures sur vingt-quatre et sept jours sur sept)</li>
                </ul>
            </ul>

        </section> 
        <section id="article6">
            <h5 className="fw-bold">Article 6 - Caractéristiques de l’autocar</h5>
            <p>
                Chaque autocar mis à disposition du donneur d’ordre par le transporteur doit être
                en bon état de marche et répondre en tous points aux obligations techniques réglementaires
                adapté à la distance à parcourir, aux caractéristiques du groupe et aux exigences éventuelles du donneur d’ordre
                compatible avec le poids et le volume des bagages prévus.
            </p>
            <p>
                Dans le cas ou le véhicules dispose de toilettes chimiques, celles-ci ne sont accessible qu’avec l’autorisation du conducteur pour des motifs environnementaux.
                Il est préférable d’attendre les pauses afin d’utiliser des toilettes publiques.
            </p>
            <p>
                Certains véhicules disposent d’un équipement audio et vidéo pour l’agrément du voyage.
                Cap Event ne pourra être tenu responsable d’un dysfonctionnement de ces appareils et ne pourra pas être redevable d’une quelconque indemnisation au regard de ces dysfonctionnements.
                Les passagers sont responsables des dégradations occasionnées par leur fait à l’autocar.
                Toute dégradation relevée à l’intérieur de l’autocar et causée par les passagers sera facturée au client.
                Le client est tenu de constater et informer le conducteur de l’autocar si des dégradations étaient constatées avant le départ du voyage.
                Le client et le conducteur sont tenus de vérifier ensemble si d’éventuelles dégradations sont intervenues avant le retour du véhicule à son garage.
                En l’absence de constat bilatéral, la société se réserve le droit de constater des dégradations également une fois le véhicule rentré à notre garage jusqu’à la location suivante.
            </p>
        </section> 
        <section id="article7">
            <h5 className="fw-bold">Article 7 - Sécurité à bord de l'autocar</h5>
            <p>
                Le nombre maximal de personnes pouvant être transportées ne peut excéder celui inscrit sur l’attestation d’aménagement ou la carte violette.
                Le transporteur est responsable de la sécurité du transport, y compris lors de chaque montée et de chaque descente des passagers de l’autocar.
                Les instructions du personnel roulant et d'accompagnement doivent être respectées.
            </p>
            <p>
                Le personnel affecté à la conduite et au trajet est autorisé à exclure toute personne sous l'emprise manifeste d'alcool ou de drogues.
                Il est interdit de fumer à bord du bus. Cette interdiction s'applique également aux cigarettes électroniques.
                Il en va de même pour les passagers qui compromettent la sécurité des autres passagers ou perturbent considérablement le bien-être de ces derniers.
                Dans ce cas, les passagers ne peuvent exercer aucun droit quant à un moyen de transport de remplacement.
            </p>
            <p>
                Le contrat de transport peut être annulé sans préavis si un passager se comporte de manière inappropriée, et ce, malgré un avertissement (verbal).
                En cas de manquement à cette obligation ou en cas de non respect des consignes établies par le chauffeur,
                ce dernier peut de plein droit refuser d’effectuer la prestation tant que les conditions de sécurité ne sont pas respectées.
                Dans le cas où le transporteur ne peut effectuer sa prestation dans les conditions de sécurité, aucun rembousement, ni réclamation, ne pourront être demandé.
            </p>
            <p>
                Des arrêts sont laissés à l’initiative du transporteur ou du conducteur pour répondre aux obligations de sécurité et de respect de la réglementation sociale relative aux temps de conduite et de repos des conducteurs, ou à d’autres nécessités.
            </p>
            <p>
                Conformément aux dispositions légales applicables, tous les passagers sont tenus d'utiliser la ceinture de sécurité, dans la mesure où le bus en est équipé.
            </p>
            <p>
                S’il s’agit d’un groupe accompagné, le transporteur comme le conducteur doivent connaître le nom des personnes ayant une responsabilité d’organisation ou de surveillance, dont la nature doit être précisée. Ces personnes désignées comme responsables doivent connaître les conditions d’organisation du transport convenues avec le transporteur et détenir la liste des personnes composant le groupe. Le donneur d’ordre doit prendre les dispositions pour que ces informations leur soient communiquées avant le début du transport.
                A la demande du donneur d’ordre, le conducteur donne avant le départ une information sur les mesures et les dispositifs de sécurité, adaptée à la nature du service et aux passagers. Si l’autocar en est équipé, le siège basculant, dit siège de convoyeur, est uniquement réservé à un conducteur ou à un membre d’équipage.
                Sauf dérogations légales, le transport de marchandises dangereuses est interdit dans les autocars.
                Si une dérogation s’applique, le donneur d’ordre informe le transporteur.
            </p>
            <p>
                Concernant plus spécifiquement les transports en commun d’enfants :</p>
            <p>
                Le conducteur doit :
            </p>
            <ul>
                <li>s’assurer de la présence des pictogrammes réglementaires du signal de transport d’enfants</li>
                <li>utiliser impérativement le signal de détresse à l’arrêt de l’autocar lors de la montée ou de la descente des enfants</li>
                <li>employer les mesures de protection de façon adaptée en cas d’arrêt prolongé de l’autocar</li>
            </ul>
            <p> Le donneur d’ordre doit :</p>
            <ul>
                <li>veiller à ce que les personnes désignées comme responsables aient les connaissances nécessaires en matière de sécurité pour les transports en commun d’enfants</li>
                <li>demander aux personnes désignées comme responsables de dispenser les consignes de sécurité à appliquer (danger autour de l’autocar, obligation de rester assis…), notamment celle concernant le port obligatoire de la ceinture de sécurité, et de veiller à leur respect</li>
                <li>donner consigne aux personnes désignées comme responsables de compter les enfants un à un lors de chaque montée et descente de l’autocar</li>
                <li>veiller à répartir dans l’autocar les accompagnateurs en liaison avec le conducteur, notamment en fonction des exigences de sécurité</li>
            </ul>
        </section>  
        <section id="article8">
            <h5 className="fw-bold">Article 8 - Bagages</h5>
            <p>
                Le transporteur n’est pas responsable des bagages placés en soute. Ces bagages doivent faire l’objet d’un étiquetage par leur propriétaire.
                En cas de perte ou d’avarie de bagages placés en soute, aucune indemnité ne pourra être réclamée par le donneur d’ordre ou autres passagers du groupe transporté.
                Le transporteur, ou son préposé-conducteur, se réserve le droit de refuser les bagages dont le poids, les dimensions ou la nature ne correspondent pas à ce qui avait été convenu avec le donneur d’ordre, ainsi que ceux qu’il estime préjudiciable à la sécurité du transport. Les bagages à main, dont le passager conserve la garde, demeurent sous son entière responsabilité.
                Avant l’exécution du service, le donneur d’ordre informe chaque passager des dispositions ci-dessus, notamment en ce qui concerne la garde des bagages à main et l’absence d’indemnisation des bagages placés en soute.
                A la fin du transport, le donneur d’ordre, son représentant et les passagers sont tenus de s’assurer qu’aucun objet n’a été oublié dans l’autocar. Le transporteur décline toute responsabilité en cas de détérioration ou de vol de tout ce qui pourrait y avoir été laissé.
            </p>
        </section> 
        <section id="article9">
            <h5 className="fw-bold">Article 9 - Diffusion publique de musique ou projection d’une oeuvre audiovisuelle</h5>
            <p>
                La diffusion publique dans un autocar d’oeuvres musicales, cinématographiques, télévisuelles ou d’enregistrements personnels doit faire l’objet d’une déclaration préalable et être autorisée par les titulaires de droits d’auteur.
            </p>
        </section> 
        <section id="article10">
            <h5 className="fw-bold">Article 10 - Rémunération du transport et des prestations annexes et complémentaires</h5>
            <p>
                La rémunération du transporteur comprend le prix du transport stricto sensu, qui inclut notamment la rémunération du ou des conducteurs, celui des prestations annexes et complémentaires, auxquelles s’ajoutent les frais liés à l’établissement et à la gestion administrative et informatique du contrat de transport, ainsi que toute taxe liée au transport et, ou, tout droit dont la perception est mise à la charge du transporteur.
            </p>
            <p>
                Le prix du transport est également établi en fonction du type d’autocar utilisé, de ses équipements propres, d’éventuels équipements complémentaires, du nombre de places offertes, du volume souhaité des soutes, de la distance du transport, des caractéristiques et sujétions particulières de circulation.
            </p>
            <p>
                Toute prestation annexe ou complémentaire est rémunérée au prix convenu. Tel est le cas notamment :
                <ul>
                <li>du stationnement de longue durée sur un site</li>
                <li>des transferts aériens, ferroviaires, maritimes du ou des conducteur(s) en cas de longue période d’inactivité</li>
                <li>des transports complémentaires maritimes (ferries) ou ferroviaires (tunnels)</li>
                </ul>
                Toute modification du contrat de transport initial imputable au donneur d’ordre, telle que prévue à l’article 12, entraîne un réajustement des conditions de rémunération du transporteur.
            </p>
            <p>
                A titre d’exemples :
                <ul>
                    <li>Heure supplémentaire facturée 65€ TTC entre 06h00 et 21h00 et 95€ TTC entre 21h00 et 06h00</li>
                    <li>Kilomètre supplémentaire facturé 2€ TTC/km</li>
                </ul>
                Cette rémunération peut également être modifiée s’il survient un événement imprévu. Le prix de transport initialement convenu est révisé en cas de variations significatives des charges de l’entreprise de transport, qui tiennent à des conditions extérieures à cette dernière, tel notamment le prix des carburants, et dont la partie demanderesse justifie par tous moyens.
            </p>
            <p>
                Cap Event se réserve le droit de demander une caution au Donneur d’ordre, en fonction des caractéristiques de la prestation ou des exigences de ses prestataires. Cette caution sera restituée dans les 5 jours ouvrés suivant la fin de la prestation. Au cas où il existerait un litige sur la prestation suite à des dégradations effectuées par les passagers, un ou plusieurs changement dans les termes de la prestation tels que définis dans le bon de commande, ce remboursement s’effectuera dès que le chiffrage du litige aura été établi, et déduction faites des sommes dues à titre de dédommagement.
            </p>
        </section> 
        <section id="article11">
            <h5 className="fw-bold">Article 11 - Modalités de conclusion et de paiement du contrat</h5>
            <p>
                Le devis n’engage, ni le donneur d’ordre, ni la Société Pilot Conseils.
                Le donneur d’ordre n’est engagé qu’après signature du bon de commande.</p>
            <p>
                Pilot Conseils est engagée à délivrer la prestation qu’après réception d’un règlement partiel ou total.
                </p>
            <p>Si pour quelconque raison, Pilot Conseils n’était pas en capacité de fournir la prestation commandée, la Société fera tout son possible pour trouver une solution de remplacement. Si malgré ses efforts, aucune solution n’était trouvée, Pilot Conseils devra procéder au remboursement des sommes versées par le client dans un délai de 48H maximum à réception des coordonnées bancaires.
            </p>
            <p>Le solde du prix du transport, des prestations annexes et complémentaires, est exigible avant le début du service.
            </p>
            <p>Lorsque le transporteur consent au donneur d’ordre des délais de paiement, le bon de commande, le contrat ou la facture font mention de la date à laquelle le paiement doit intervenir.
            </p>
            <p>Toute commande implique de plein droit l’acceptation des présentes conditions générales de ventes. Toutes conditions contraires qui pourraient être stipulées par l’acheteur dans ses propres conditions générales d’achat, dans ses bons de commande, dans sa correspondances, nous sont inopposables et réputées non écrites à notre égard.
            </p>
            <p>Les seules informations valables quand à l’application de votre commande sont celles qui sont stipulées sur le bon de commande signé. Toutes autres informations, orales ou écrites, ne sont données qu’à titre indicatif et ne peuvent nous engager.
            </p>
            <p>Toute modification de la commande initiale devra être formulée par écrit préalablement à l’exécution de la prestation et fera donc l’objet d’un nouveau bon de commande.
            </p>
            <p>Le non-paiement total ou partiel d’une facture à une seule échéance emporte, sans formalité, la déchéance du terme entraînant l’exigibilité immédiate du règlement, sans mise en demeure, de toutes sommes dues, même à terme, à la date de ce manquement et autorise le transporteur à exiger le paiement comptant avant l’exécution de toute nouvelle opération.
            </p>
            <p>En cas de non paiement d’une échéance au terme convenu, ainsi qu’en cas de non respect de l’une quelconque des obligations prévues dans les présentes conditions générales de vente la prestation ne sera pas réalisée, ce plein droit et sans aucune formalité, les acomptes versés nous demeurent acquis à titre de premiers dommages et intérêts.
            </p>
            <p>Pénalités de retard de paiement :
            </p>
            <p>Le taux d’intérêt correspond au taux directeur (taux de refinancement ou Refi) mensuel de la Banque centrale européenne (BCE), en vigueur au 1er janvier ou au 1er juillet, majoré de 10 points :
                <ul>
                    <li>soit 14,00 % (4 + 10) pour les pénalités dues à partir du 1er juillet 2023,</li>
                    <li>soit 12,50 % (2,5 + 10) pour les pénalités dues à partir du 1er janvier 2023.</li>
                </ul>
                </p>
            <p>
                Le taux, annuel ou mensuel, peut être converti en taux journalier. Il est alors multiplié par le nombre de jours écoulés entre la date d’échéance et la date d’encaissement (ou la date à laquelle est fait le calcul, si le paiement n’est pas encore effectué).
            </p>
            <p>
                Il est appliqué sur le montant TTC de la facture.
            </p>
            <p>
                Les pénalités sont exigibles sans qu’un rappel ne soit nécessaire : l’envoi d’une lettre recommandée n’est pas requis pour déclencher le droit de percevoir des pénalités de retard.
            </p>
            <p>
                Elles courent dès le jour suivant la date de règlement portée sur la facture ou, à défaut, le 31e jour suivant la fin de l’exécution de la prestation de service.
            </p>
            <p>
                Les Pénalités de retard ne sont pas soumises à TVA .
            </p>
            <p>
                Indemnité pour frais de recouvrement :
            </p>
            <p>
                Une indemnité forfaitaire de 40 € est due au créancier pour frais de recouvrement, à l’occasion de tout retard de paiement.
            </p>
            <p>
                L’article L 441-6 du code de commerce, concernant les conditions générales de vente, comporte des dispositions spécifiques pour le secteur des transports qui ont été introduites successivement par la loi du 5 janvier 2006 relative à la sécurité et au développement des transports et la loi du 3 janvier 2008 pour le développement de la concurrence au service des consommateurs.
            </p>
            <p>
                Cet article prévoit que les contrats passés avec les entreprises du secteur des transports doivent fixer un délai impératif pour le règlement des prestations de transport, d’un maximum de 30 jours à compter de la date d’émission de la facture. Cette disposition est d’ordre public, ce qui implique que les contrats ne peuvent pas y déroger. Le fait de ne pas respecter les délais de paiement prévus par l’article L 441-6 du code du commerce est puni d’une amende de 15 000 euros qui, pour les sociétés, peut être portée à 75 000 euros maximum. Cet article comprend également des dispositions concernant les pénalités de retard en cas de paiement effectué, en l’espèce, après le délai de 30 jours.
            </p>

        </section> 
        <section id="article12">
            <h5 className="fw-bold">Article 12 - Résiliation su contrat de transport</h5>
            <p>
                Lorsque, avant le départ, le donneur d’ordre résilie le contrat, il doit en informer le transporteur par lettre recommandée avec accusé de réception.
                Le cas échéant, une indemnité forfaitaire sera due au transporteur, égale à :
            </p>
            <ul>
                <li>30 % du prix du service si l’annulation intervient jusqu’à 30 jours avant le départ</li>
                <li>50 % du prix du service si l’annulation intervient entre 29 et 14 jours avant le départ</li>
                <li>70 % du prix du service si l’annulation intervient entre 13 et 6 jours avant le départ</li>
                <li>100 % du prix du service si l’annulation intervient 5 jours avant départ</li>
            </ul>
            <p>
                En cas d’annulation pour force majeure Cap Event se réserve le droit de réclamer des frais de gestion de réservation qui seront étudiés au cas par cas et qui n’excéderont pas 30%  du montant de la commande.
            </p>
            <p>
                En cas de résiliation par le transporteur, le donneur d’ordre a droit au remboursement immédiat des sommes versées à l’exclusion de toute autre indemnité pour couvrir un éventuel préjudice du fait de la non réalisation du transport.
            </p>
            <p>
                Si un avoir devait être proposé au client, ce document devra être fourni pour acquitter le paiement d’une autre prestation. Les avoirs sont réputés être émis « à valoir » sur une prestation future et son valable 1 année maximum à compter de la date d’émission, sauf stipulations contraires.
            </p>
            <p>
                Les avoirs ne sont pas remboursables.
            </p>
        </section> 
        <section id="article13">
            <h5 className="fw-bold">Article 13 - Exécution du contrat de transport</h5>
            <p>
                Le donneur d’ordre accepte que le transporteur sous-traite le service à un autre transporteur public routier de personnes. Le transporteur ainsi missionné sera responsable de toutes les obligations découlant du contrat.
            </p>
            <p>
                Le client se doit de vérifier que les lieux de prise en charge et/ou de dépose qu’il a indiqué pour l’exécution de son contrat de transport, soient accessible au(x) véhicule(s), mis à disposition pour réaliser la prestation.
            </p>
            <p>
                Pour information, les dimensions d’un autocar sont variables mais l’on peut prendre comme références une longueur de 12 à 15 mètres, une largeur jusqu’à 2,55 mètres et une hauteur de 3,5 à 4 mètres.
            </p>
            <p>
                Le transporteur pourra modifier à la commande ou le jour du service les adresses de prise en charge et/ou de dépose, si les conditions ne permettent pas l’accès du/des véhicule(s) à ses adresses.
            </p>
            <p>
                Le client ne pourra prétendre à aucune indemnité si la prestation ne se déroule pas dans les conditions définies par le bon de commande, suite à un problème d’accessibilité du ou des véhicule(s)aux adresses de prise en charge ou de dépose qui auront été indiquées par le client.
            </p>
        </section> 
        <section id="article14">
            <h5 className="fw-bold">Article 14 - Modification du contrat de transport en cours de réalisation</h5>
            <p>
                Toute nouvelle instruction du donneur d’ordre ayant pour objet la modification des conditions initiales d’exécution du transport en cours de réalisation doit être confirmée immédiatement au transporteur par écrit ou par tout autre procédé en permettant la mémorisation.
            </p>
            <p>
                Le transporteur n’est pas tenu d’accepter ces nouvelles instructions, notamment si elles sont de nature à l’empêcher d’honorer les engagements de transport pris initialement. Il doit en aviser immédiatement le donneur d’ordre par écrit ou par tout autre procédé en permettant la mémorisation.
            </p>
            <p>
                Toute modification au contrat peut entraîner un réajustement du prix convenu
            </p>
        </section> 
        <section id="article15">
            <h5 className="fw-bold">Article 15 - Evénements fortuits</h5>
            <p>
                Les horaires de départ et d’arrivée ainsi que les itinéraires sont mentionnés à titre indicatif et sont susceptibles de modifications par le transporteur si les circonstances l’imposent notamment pour des raisons de législation, de sécurité, de cas fortuit ou de force majeure. Aucun dédommagement ni remboursement ne seront accordés au client dans ces circonstances.
            </p>
            <p>
                Le client ne pourra prétendre à aucune indemnité si l’annulation du contrat, du fait du transporteur, est imposée par des circonstances de force majeure, des raisons tenant à la sécurité des voyageurs ou toute raison indépendante de la volonté du transporteur.
            </p>
            <p>
                Si le voyage devait être modifié en cas d’événement fortuit ou de force majeure aucun remboursement ou dédommagement ne sera accordé au client.
            </p>
            <p>
                Pour les bons de commandes signés à un prix convenu entre le transporteur et le donneur d’ordre plus d’un mois avant le départ, le transporteur peut de façon tout à fait exceptionnelle être amené à modifier son prix jusqu’à 1 mois avant le début de la prestation, en fonction d’évènements économiques modifiant le coût de revient de la prestation (augmentation du prix des carburants…). Dans ce cas le transporteur fera une autre offre au donneur d’ordre, qui sera libre de l’accepter ou de la refuser. En cas de refus, le bon de commande sera alors annulé et le transporteur remboursera sans délai le donneur d’ordre des sommes déjà versées. Le donneur d’ordre ne pourra prétendre à aucune autre indemnisation du fait de cette annulation.
            </p>
        </section> 
        <section id="article16">
            <h5 className="fw-bold">Article 16 - Dégradations et cautions</h5>
            <p>
                Lors de l’organisation de certains types de déplacements, la Société Pilot Conseils, peut être amenée à demander au client un chèque de caution visant à garantir contre le préjudice d’éventuelles dégradations pouvant survenir.
            </p>
            <p>
                Ce chèque ne sera pas encaissé, dès lors qu’aucune dégradation n’aura été constatée par le transporteur. Ce chèque sera alors détruit par Pilot Conseils ou renvoyé au client s’il en fait la demande.
            </p>
            <p> 
                En cas de dégradations survenues lors du service du fait du comportement des passagers, la Société Pilot Conseils, se réserve le droit d’encaisser le chèque de caution et de restituer la différence de montant qui pourrait exister entre le prix des réparations et le montant de la caution. Le remboursement de cette différence interviendra dans le délai maximum d’un mois après l’encaissement de la caution
            </p>
            <p>
                Les réparations peuvent entrainer une indisponibilité du véhicule, aussi, la Société Pilot Conseils se réserve le droit d’imputer sur la caution, un forfait de 500€ H.T. par jour d’immobilisation à titre d’indemnisation de ce préjudice en supplément des frais de réparation ou de remise en état.
            </p>
            <p>
                Tous montants prélevés sur la caution, feront l’objet d’une facture émise par la Société Pilot Conseils et adressée au client. Cette facture fera état des différentes réparations ou remises en état.
            </p>
            <p>
                Les véhicules fournis pour effectuer les prestations des clients de la Société Pilot Conseils étant réputés en bon état d’usage, il appartient au client, d’effectuer un état des lieux contradictoire avec le chauffeur, avant le début du service et à la fin du service. Dans l’éventualité de dégradations constatées, aucune réclamation de la part du Client ne pourra être reçue en l’absence d’un état des lieux contradictoire comportant la signature du chauffeur ayant effectué la prestation.
            </p>
        </section> 
        <section id="article17">
            <h5 className="fw-bold">Article 17 - Retards</h5>
            <p>
                Le transporteur ne pourra être tenu pour responsable des retards dus à des événements indépendants de sa volonté (par exemple : les pannes mécaniques, les embouteillages, les accidents, les grèves, les conditions climatiques, les déviations, le fait d’un ou de plusieurs passagers, le fait d’un tiers, tout cas fortuit ou de force majeure) ou encore dictés par la nécessité d’assurer la sécurité des personnes transportées. Aucune indemnité ni remboursement ne seront accordés au client dans ces circonstances.
            </p>
            <p>
                En cas d’incident technique, le prestataire s’engage à mettre tout en oeuvre pour assurer la prestation du client (mise à disposition d’un autre véhicule, hors cas de force majeure; faire appel à sa garantie d’assistance pour assurer l’acheminement des voyageurs).
            </p>
            <p>
                En cas de retard à un aéroport, à une gare ou tout autre lieu de rendez-vous les éventuels frais d’hôtellerie, de restauration, de train, de taxi ou de tout autre frais consécutifs à ce retard, ne seront pas pris en charge par notre société.
            </p>
            <p>
                Si le client décide de son propre chef d’utiliser d’autres moyens de transport que ceux proposés sur son devis, pour quelque raison que ce soit, il ne pourra prétendre à aucune indemnisation.
            </p>
            <p>
                Le client ne peut pas modifier directement avec le conducteur du car, les conditions prévues au bon de commande initial sans informer la Société Pilot Conseils et obtenir de cette dernière un accord écrit. Toutes modifications concernant notamment le trajet ou les horaires de départ prévus doivent impérativement recevoir l’acceptation écrite de la Société Pilot Conseils.
            </p>
            <p>
                Le Client se doit de respecter impérativement les horaires de départ prévu, car le non respect peut entraîner des modifications importantes dans l’organisation des plannings de transport. En conséquence, le chauffeur constatant un retard de plus de 30 mn du groupe dont il a la charge, pourra continuer son service en l’absence d’instructions contraires.
            </p>
            <p>
                Le Client ne pourra se prévaloir d’un quelconque préjudice du fait de la non réalisation de la prestation due à son retard par rapport à l’horaire prévu. Le Client prendra ses dispositions pour assurer à sa charge l’organisation de son transport. Il restera néanmoins redevable auprès de la Société Pilot Conseils de l’intégralité du paiement de la prestation prévue.
            </p>
            <p>
                D’une façon générale, aucune indemnisation de préjudice ne pourra être réclamée au transporteur, pour les conséquences que le donneur d’ordre ou les passagers auraient à subir des suites de la survenance d’un évènement ayant pu occasionner des retards ou l’annulation du transport prévu.
            </p>
        </section> 
        <section id="article18">
            <h5 className="fw-bold">Article 18 - Formalités</h5>
            <p>
                Pour les déplacements à l’étranger, chaque participant est invité à se renseigner sur les législations de police et douanière en vigueur et à s’y conformer.
                Cap Event ne saurait être tenu pour responsable de toute infraction à ces règles.
            </p>
        </section> 
        <section id="article19">
            <h5 className="fw-bold">Article 19 - Utilisation des données</h5>
            <p>
                Pilot Conseils n’utilise les données collectées sur le site que dans le cadre de sa relation avec l’internaute. Le nom des clients (Société, Collectivité, Administration ou Association pourra être publié à titre de référence sauf si le client s’y oppose formellement. L’identité de la personne physique ne sera quant à elle jamais publiée.
            </p>
        </section> 
        <section id="article20">
            <h5 className="fw-bold">Article 20 - Réclamations</h5>
            <p>
                Toute réclamation devra nous parvenir par lettre recommandée avec accusé de réception, dans les huit jours qui suivent l’exécution de la commande. Au-delà, aucune réclamation ou contestation ne pourra plus être formulée, remboursée ou indemnisée.
            </p>
        </section> 
        <section id="article21">
            <h5 className="fw-bold">Article 21 - Différents et attribution de juridiction</h5>
            <p>
                Tous différents pouvant résulter de l’application de nos contrats sont de la compétence exclusive du Tribunal de Commerce de Nanterre.
                Toute réclamation sera acceptée dans le délai de prescription commerciale de droit commun.
            </p>
        </section>        
        
        </Col>
    </Row>    
    </>
  );
};

export default ConditionsGenerales;
