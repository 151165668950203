import { createContext } from 'react';

// Spécifiez le type attendu pour le contexte en tant qu'argument générique
const UserContext = createContext<{
  user: any;
  userProfile: any;
  setUser: React.Dispatch<any>;
  setUserProfile: React.Dispatch<any>;
}>({
  user: undefined, // Remplacez undefined par une valeur par défaut si nécessaire
  userProfile: undefined,
  setUser: () => {},
  setUserProfile: () => {},
});

export default UserContext;