import React, { useContext, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { onValue, ref } from "firebase/database";
import UserContext from "../../utils/usercontext";


const Login: React.FC = () => {

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState("")
    const navigate = useNavigate()
    const { setUserProfile } = useContext(UserContext);
    
    async function signIn() {
        try {
            setError("");
            const email = emailRef.current!.value;
            const password = passwordRef.current!.value;
            
            // Authentification avec l'adresse e-mail et le mot de passe
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const token = await userCredential.user.getIdToken();
            console.log(token)
            const response = await fetch(`${process.env.REACT_APP_SERVEURADRESS}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
                credentials: 'include'
            });
            const user = userCredential.user;

            if (user) {
                const userProfileRef = ref(dbCollection, 'users/' + user.uid);

                onValue(userProfileRef, (snapshot) => {
                    const userProfileData = snapshot.val();
                    setUserProfile(userProfileData);
                });
            } else {
                setError("Impossible de vous connecter");
            }
        } catch {
          setError("Impossible de vous connecter");
        }
    }
      
    async function handleSubmit(e: any) {
        e.preventDefault();
        await signIn();
        navigate('/')
    }

    return (
        <>
            <Card className="border-0 mx-auto">
                <Card.Body className="mx-auto">
                <h2 className="text-center mb-4 text-kease">Connexion</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form className="mx-auto" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 mx-auto" id="email">
                        <Form.Control className="mx-auto" type="email" ref={emailRef} placeholder="email" required />
                    </Form.Group>
                    <Form.Group className="mb-3 mx-auto" id="password">
                        <Form.Control className="mx-auto" type="password" ref={passwordRef} placeholder="Mot de passe" required />
                    </Form.Group>
                    <Button className="mx-auto w-100 btn-kease" type="submit">
                    Se connecter
                    </Button>
                </Form>
                <br/>
                <div className="mx-auto text-center mb-3">
                    <Link to="/forgot-password">Oublie de votre mot de passe?</Link>
                </div>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto">
                <Card.Text className="mx-auto">
                    Vous n'avez pas encore de compte ? <Link to="/create-account">S'inscrire</Link>
                </Card.Text>
            </Card >
            <br/>
        </>
    )
}

export default Login