import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Testimonials: React.FC = () => {
  return (
    <>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
    <div className="justify-content-center">
      <h1 className="bg-dark text-center m-0">Témoignages clients</h1>
       <br/>
      <p>
        L'avis de nos clients compte pour nous mais aussi pour vous, c'est notre première garantie de confiance avec nos futurs clients.
        C'est pourquoi nous demandons une notation pour chaque prestation que nous effectuons et
        ces notations sont directement envoyées sur notre site, sans possibilité de notre part de la modifier.
      </p>
      <br/>

        <Row className="card-group">
          <Col md={4} className="m-0">
            <Card className="border-0">
              <Card.Body>
                <Row>
                  <Col xs={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFC107" className="bi bi-person-square" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z" />
                    </svg>
                  </Col>
                  <Col xs={10}>
                    <div className="card-title">
                      <h6>Manon</h6>
                      <p>Société Evènementielle</p>
                    </div>
                  </Col>
                </Row>
                <p className="card-text">
                  <em>
                    Nos clients ont été très satisfaits de la prestation logistique.
                    Vraiment rien à dire. Les chauffeurs sont très professionnels.
                    De plus, l'équipe est réactive et répond rapidement aux besoins des clients.
                    Un grand merci à toute l'équipe de CAP EVENT qui nous a permis la réussite de l'évènement !
                  </em>
                </p>
                <div className="d-flex align-items-center">
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <p className="card-text mx-2"><small className="text-muted">publié le 30/06/2023</small></p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          
          <Col md={4} className="m-0">
            <Card className="border-0">
              <Card.Body>
                <Row>
                  <Col xs={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFC107" className="bi bi-person-square" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z" />
                    </svg>
                  </Col>
                  <Col xs={10}>
                    <div className="card-title">
                      <h6>Thomas</h6>
                      <p>Société du CAC 40</p>
                    </div>
                  </Col>
                </Row>
                <p className="card-text">
                  <em>
                    Réactif, dynamique, l'équipe nous aide à organiser des visites scolaires de
                    plusieurs lycées sur une semaine à destination de nos sites.
                    Tout se passe toujours très bien
                  </em>
                </p>
                <div className="d-flex align-items-center">
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <span className="fa fa-star checked"></span>
                  <p className="card-text mx-2"><small className="text-muted">publié le 17/04/2023</small></p>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="m-0">
            <Card className="border-0">
              <Card.Body>
                <Row>
                  <Col xs={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFC107" className="bi bi-person-square" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z" />
                    </svg>
                  </Col>
                  <Col xs={10}>
                    <div className="card-title">
                      <h6>Elisabeth</h6>
                      <p>Société du CAC 40</p>
                    </div>
                  </Col>
                </Row>
                <p className="card-text">
                  <em>
                    Le groupe déplace régulièrement nos clients et c'est toujours une réussite,
                    tenue des engagements, du planning et des prestations.
                    Le chauffeur est présent, efficace et discret quand il faut.
                  </em>
                </p>
                <div className="d-flex align-items-center">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star-half-o checked"></span>
                    <p className="card-text mx-2"><small className="text-muted">publié le 04/03/2023</small></p>
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

    </div>
    <br/>
    </>
  );
}

export default Testimonials;
